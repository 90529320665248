import router from '../router'
import { message } from 'ant-design-vue'

let showMessage = true
export const errorFuc = (error) => {
  
  Promise.reject(error.response)
  if (!error.response) {
    message.error('服务器异常！')
  } else if (error.response && error.response.status === 401) {
    if (showMessage) {
      showMessage = false
      message.error('登录已失效！')
      setTimeout(() => {
        showMessage = true
      }, 1000)
      sessionStorage.clear()
      router.replace({ name: 'login' })
    }
  } else if (error.response && error.response.status === 403) {
    message.error('您的权限不足，无权限操作，请联系管理员！')
  } else if (error.response && error.response.status === 500) {
    message.error('服务器请求超时！')
  } else if (error.message.indexOf('timeout') > -1) {
    message.error('请求超时！')
  }
  return Promise.reject(error.response)
}
