<template>
  <div>
    <router-view v-if="isRouterAlive"></router-view>
    <caluse :isShow="auth!=='1'" />
  </div>
</template>

<script>
  import { useStore } from 'vuex'
  import { onUnmounted, onMounted, ref, computed, nextTick } from 'vue'
  import Caluse from '@/components/caluse'

  export default {
    components: {
      Caluse,
    },
    provide() {
      return {
        reload: this.reload,
      }
    },
    setup() {
      const store = useStore()
      const isRouterAlive = ref(true)
      const auth = computed(() => store.state.authorization)
      const authorization = localStorage.getItem('authorization') || '0'
      store.commit('SET_AUTH', authorization)

      onMounted(() => {
        store.dispatch('FETCH_DICTIONARY')
      })

      return {
        auth,
        isRouterAlive,
      }
    },
    methods: {
      reload() {
        this.isRouterAlive = false
        this.$nextTick(() => {
          this.isRouterAlive = true
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>