module.exports = {
  navigation: {
    home: '首頁',
    aboutUs: '關於安昇',
    platform: '安昇平台',
    services: '安昇服務',
    insights: '市場觀察',
    clients: '安昇客戶',
    contactUs: '聯絡我們',
    privacy: '免責聲明',
    address: '香港中環干諾道中34-37號華懋大廈19樓1902室',
    tel: '電話',
    email: '電郵',
    subscribe1: '會員訂閱',
    subscribe: '會員訂閱',
  },
  homePage: {
    aboutUs: '了解安昇',
    aboutUsInfo: '安昇金融致力於為大中華地區高淨值人士提供財富管理服務。',
    ourMission: '安昇使命',
    ourMissionInfo: '安昇致力於以帶動全行業效率提升的創新模式，為安昇合作夥伴和客戶謀求更健康的財富未來。',
    ourServices: '安昇服務',
    ourServicesInfo: '安昇商業模式的核心是整合財富管理社群各方於 一站式金融市場的數字化平台。',
    more: '了解更多',
    middleTit: '通過一站式數字化平台，為高淨值人士定制財富管理服務，<br/>為合作夥伴提供創新的金融解決方案',
    ourPlatform: '我們的平台',
    ourPlatformInfo: '開放、共享、一站式、數字化',
    mandarin: '普通話',
    cantonese: '廣東話',
    ourPartners: '合作夥伴',
    ourPartnersInfo: '聯結、合作、無界、共贏',
    carousel1: {
      tit: '個人客戶資產配置方案',
      content: '利用平台的優勢幫助個人投資者模擬高端機構資產配置，無論是基礎配置還是定制配置需求都能靈活滿足。',
    },
    carousel2: {
      tit: '家族客戶專屬財富管理',
      content: '根據需求進行設計和實施一站式操作，使用基金架構綜合管理小群體(家族)資產配置、分配及傳承。',
    },
    carousel3: {
      tit: '全方位的合作夥伴支持',
      content: '合法合規丶資產安全丶操作便捷丶方案靈活',
    },
    newsTit: '獲取安昇最新行業趨勢觀察與分析',
    news1: '從比特幣看虛擬資產的資產配置價值',
    news2: '國企信用債違約、房地產投資及個人資產配置',
    news3: '去全球化影響下的家庭資產配置',
  },
  insights: {
    tit: '獲取安昇最新行業趨勢觀察與分析',
    more: '查看更多',
    subscribe: '訂閱',
    back: '返回',
    news1: '從比特幣看虛擬資產的資產配置價值',
    news1_content: '從比特幣看虛擬資產的資產配置價值',
    news2: '國企信用債違約、房地產投資及個人資產配置',
    news2_content: '國企信用債違約、房地產投資及個人資產配置',
    news3: '去全球化影響下的家庭資產配置',
    news3_content: '去全球化影響下的家庭資產配置',
    news4: '雙重國籍的稅務風險及應對策略',
    news4_content: '雙重國籍的稅務風險及應對策略',
    news5: '第三方資產管理 (EAM/IAM)',
    news5_content: '第三方資產管理(EAM/IAM):<br/>高淨值財富管理新模式',
  },
  clients: {
    more: '了解更多',
    title: '財富管理方案及專業配套服務',
    subhead: '資產配置理念',
    idea: {
      a: '投資目的',
      b: '投資期限',
      c: '風險容忍度',
      d: '稅務責任考慮',
      e: '特殊需求',
    },
    middleTit: '突破個人配置局限',
    middleSubhead: '利用服務平台的優勢幫助個人投資者模擬高端機構資產配置，<br/>無論是基礎配置還是定制配置需求都能靈活滿足',
    describe: {
      more: '了解更多',
      basic: {
        tit: '基礎資產配置',
        content: '包括一般現金管理、固定收益投資、權益類投資。投資者可以通過一籃子產品便捷地達到合適匹配個人的基礎配置。',
      },

      customised: {
        tit: '定制資產配置',
        content: '定制配置除了基礎配置內容以外，還包含其他非公開的另類投資類別，此類投資產品個人受限於規模和管道，一般難以獲取。',
      },
    },
    project: {
      tit: '推薦方案',
      subhead: '為不同資產規模的個人與家族客戶定制不同解決方案',
      more: '了解更多',
      arrowStep: {
        a: '起步：保值',
        b: '進階：增值',
        c: '成熟：傳承價值',
      },
    },
    project1: {
      tit: '資金規模 : 200萬美元以下',
      step1: '按個人需求配置策略基金',
      step2: '為個人財富配置實現保值和增值',
      advice: '配置建議: 基礎資產配置',
    },
    project2: {
      tit: '資金規模 : 200-500萬美元',
      step1: '按個人需求配置策略基金',
      step2: '為個人財富配置實現保值和增值',
      step3: 'EAM管理',
      advice: '配置建議: 基礎+小比例定制配置',
    },
    project3: {
      tit: '資金規模 : 500萬美元以上',
      step1: '專設個人、家族綜合基金',
      step2: '同時完成財富配置及家族分配等需求',
      step3: 'EAM管理',
      advice: '配置建議: 定制化進階配置',
    },
    project4: {
      tit: '資金規模 : 2000萬美元以上',
      step1: '獨立個人、家族基金',
      step2: '同時完成財富配置、家族分配、私人公司持有的安排和管理',
      advice: '配置建議: 全功能定制化進階配置',
    },
    framework: {
      tit: '架構嚴謹，權責分明，保障客戶資產安全',
      fund: {
        tit: '基金架構',
        content: '基金及獨立外部服務機構權<br />責分別，確保流程嚴謹，<br />保護客戶資產安全。',
      },
      authority: {
        tit: '權限架構',
        content: '投資經理、基金行政管理人、<br />審計師及託管銀行相互獨立，<br />共同保障投資者資產。',
      },
      eam: {
        tit: 'EAM架構',
        content: '提供EAM平台、助力獨立資產管<br />理者為投資者提供優質服務。',
      },
    },
  },

  //   关于安昇
  aboutUs: {
    bannerTitle: '投資有道，共享無界',
    ourMission: '安昇使命',
    ourMissionText:
      '安昇聚合金融產品、服務、資訊各方於開放、共用的一站式服務平台，共同為客戶打造多元、無界、定制化、全球化的財富管理和配置方案。 安昇致力於以帶動全行業效率提升的創新模式，在變動的全球局勢下，為安昇合作夥伴和客戶謀求更健康的財富未來。',

    ourBackground: '安昇簡介',
    ourBackgroundTextP1:
      '安昇金融控股有限公司 (安昇) 致力於為大中華地區高淨值人士提供財富管理服務。安昇位於香港，與合作夥伴形成覆蓋全球主要市場的經營和產品網路。安昇金控旗下兩間子公司 - 新盛資產管理有限公司持有香港證監會提供資產管理的第9 類牌照，而安昇金融有限公司持有提供證券交易和諮詢的第 1 類和第 4 類牌照。此外，我們的業務合作夥伴 - 資保伙伴有限公司 和 Asset Amplifier Company Limited 分別提供信託及公司服務  (TCSP) 和放債業務。合法、合規、誠信以及客戶的資產安全保障是安昇經營的基礎。',
    ourBackgroundTextP2:
      '安昇團隊由金融界和科技界資深專業人士組成。公司自主研發的數位化平台融合了對金融市場的洞見和創新科技的優勢，為合作夥伴和客戶創造更緊密的聯結與合作條件，為客戶帶來個性化解決方案和優質的投資產品。',
    ourBackgroundTextP3:
      '安昇借助共用平台的優勢，以共贏為合作基礎，為合作夥伴提供全面的專業諮詢及配套服務，包括公司和基金的設立，法務、稅務的諮詢，IT、市場推廣、基金行政管理服務等等。安昇的一站式支持，助力合作夥伴在獲客、營收、效率、新業務開發等多方面持續拓展。',
    ourBackgroundTextP4:
      '同時，安昇與多類機構展開合作，打造多元、靈活的財富管理方案。這些定制化的解決方案，突破個人理財因規模、經驗、資源、地域等因素造成的制約，切實滿足不同資產規模的個人或家族客戶在全球資產配置、家族財富傳承與託管、高端品質生活等多方面的需求，力求覆蓋客戶對保值、增值和風險管控的多層次要求。',
    ourBackgroundTextP5:
      '安昇信奉開放、共用、利他的企業文化，並努力落實在業務的規劃和運作的細節中。安昇冀與合作夥伴長期合作，為客戶進行長遠而周全的財富管理與規劃，謀求平台各方更好的財富未來，實現多方共贏。',
    ourPlatform: '安昇服務平台',
    ourPlatformTextP1: '平台的優勢為我們的合作夥伴在客戶獲取、創收和業務發展方面創造了機會。該平台還帶來了個人投資者或個人顧問無法獲得的產品和分配解決方案。',
    ourPlatformTextP2:
      '平台對金融和科技領域最新成果的應用，又為各方帶來極大的效率和靈活性，突破了傳統財富管理模式中的地域限制、金融資產與數位資產之間的障礙，以及不同產品種類之間的隔閡，從而釋放出惠及各方的無界無限的機會。',
    ASCulture: '安昇文化',
    ASCultureText:
      '在兼容並蓄的平台背後，是安昇開放、共享、利他的文化。我們的平台允許財富管理價值鏈各端點的合作夥伴和優質產品隨時加入。我們的聚焦是走在市場的前面，切實滿足客戶的需求，我們的追求是與合作夥伴共同為客戶打造理想的財富管理方案，共享服務客戶的成果。',
    ourCommitment: '安昇承諾',
    ourCommitmentText:
      '安昇將誠信和保護客戶私隱與財產安全置於一切要務之首，合規對我們至關重要。安昇金融金控旗下兩間子公司 - 新盛資產管理有限公司持有香港證監會提供資產管理的第9 類牌照，而安昇金融有限公司持有提供證券交易和諮詢的第 1 類和第 4 類牌照。此外，我們的業務合作夥伴 - 資保伙伴有限公司 和 Asset Amplifier Company Limited 分別提供信託及公司服務  (TCSP) 和放債業務。',
    ourTeam: '安昇團隊',
    ourTeamText: '以金融和科技為主要背景的國際化專業團隊',
    Chairman: '陸戎 - 主席',
    ChairmanDetail1: '在金融領域擁有超過25年專業經驗',
    ChairmanDetail2: '現任安昇主席，負責領導和監督董事會的表現',
    ChairmanDetail3: '前華泰國際總裁，建立華泰証券海外的機構業務平台，包括投行、証券銷售交易與研究及結構私募債投資，財富管理等',
    ChairmanDetail4: '中銀國際金融產品板塊創始人及主管、公司管理委員會委員、董事，並在美林、瑞信等國際投行任職多年',
    ChiefExecutiveOfficer: '楊祖威, CFA - 首席執行官',
    ChiefExecutiveOfficerDetail1: '在金融和金融科技領域擁有超過20年專業經驗',
    ChiefExecutiveOfficerDetail2: '現任安昇首席執行官，負責業務戰略規劃和發展、投資戰略、風險管理和行銷',
    ChiefExecutiveOfficerDetail3: '前金融科技公司Prive Technologies Ltd.合夥人',
    ChiefExecutiveOfficerDetail4: '曾任德國商業銀行大中華地區銷售主管，摩根大通結構信用產品亞太區銷售部主管',
    Investor: '趙建公 - 安昇、新盛創始人/主要投資人',
    InvestorDetail1: '在企業投融資、私募股權、股權交易和投資組合管理領域擁有超過25年的專業經驗',
    InvestorDetail2: '安昇主要創始人',
    InvestorDetail3: '曾任銀建國際（171.HK）副總經理',
    SeniorAdvisor: '吳煒樑 - 資深顧問',
    SeniorAdvisorDetail1: '在金融和商業運營方面擁有超過20年專業經驗，在股票、固定收益、外匯方面擁有豐富的知識',
    SeniorAdvisorDetail2: '現任安昇資深顧問，負責為公司的運營和業務發展提供建議',
    SeniorAdvisorDetail3: '香港虛擬資產交易所董事',
    SeniorAdvisorDetail4: '前幣成科技（香港）首席運營官、中信期貨國際董事總經理，並曾任職於摩根士丹利摩根大通和萬方資產管理',
    ChiefProductOfficer: '王川 - 首席產品官',
    ChiefProductOfficerDetail1: '在專業投資產品交易、投資組合及風險管理領域擁有超過25年的專業經驗',
    ChiefProductOfficerDetail2: '現任安昇首席產品官，負責設計新基金產品和監督運營活動',
    ChiefProductOfficerDetail3: '曾在多家中外投行、券商公司包括: 瑞士銀行（UBS）、中銀國際、渣打，東方證券等擔任資深/首席交易員',
    ChiefProductOfficerDetail4: '曾擔任對沖基金經理，高級風控分析師，具備豐富的多類型投資策略及風險管理經驗',
    SeniorPortfolioManager: '羅永健 - 虛擬資產業務總監',
    SeniorPortfolioManagerDetail1: '在投資管理領域擁有超過20年專業經驗，包括大約4年在虛擬資產領域的專業經驗',
    SeniorPortfolioManagerDetail2: '現任安昇資深基金經理，負責領導投資組合投資、量化交易和研究',
    SeniorPortfolioManagerDetail3: '對另類投資市場包括: 虛擬資產、創業投資、傳統固定收益以及新興高收益市場具備豐富的知識',
    SeniorPortfolioManagerDetail4: '前數字對沖基金首席投資官、曾在富達、品浩 (PIMCO)、法國巴黎銀行資產管理從事投資、風險管理及分析工作',
    ChiefClientOfficer: '張晶 - 首席業務拓展官/首席客戶官',
    ChiefClientOfficerDetail1: '在投融資、投資者關係及國際合作領域擁有逾10年的專業經驗，具備豐富的金融科技領域實踐經驗',
    ChiefClientOfficerDetail2: '現任安昇首席客戶官，負責戰略客戶管理，專注于綜合客戶諮詢，包括客戶體驗和銷售與行銷',
    ChiefClientOfficerDetail3: '曾任職恒大集團和中融控股集團，負責專案前期開發、投融資及公共關係',
    ChiefClientOfficerDetail4: '曾任職於匯邦投資，負責澳洲及英國開發商的專案開發、與各金融機構的戰略合作談判及投資者關係維護',
    ChiefTechnologyOfficer: '李貴寧 - 首席技術官',
    ChiefTechnologyOfficerDetail1: '在技術領域擁有超過15年的專業經驗',
    ChiefTechnologyOfficerDetail2: '現任安昇首席技術官，負責公司內所有技術系統、流程軟體的設計和開發',
    ChiefTechnologyOfficerDetail3: '曾任職於華為、支付寶，從事安全存儲系統、分散式檔案系統、雲端存儲系統、金融雲開發和系統架構設計等方面的工作',
    ChiefTechnologyOfficerDetail4: '在區塊鏈、去中心化金融方面有深厚的專業認識和應用經驗',
  },
  //安昇平台
  platform: {
    bannerTitle: '穩健的全牌照金融平台',
    AscentFinancialPartners: '安昇金融',
    NewVisionAssetManagement: '新盛資管',
    SFCA: '(證監會1,4號牌)',
    SFCN: '(證監會9號牌)',
    MORE: '了解更多',
    TCSP: '信託或公司服務提供者(TCSP)',
    TCSPtext: '服務由業務夥伴提供',
    LicensedMoneyLender: '持牌放債人',
    LicensedMoneyLendertext: '與業務夥伴合作 - Asset Amplifier Company Limited',
    FundAdministrator: '基金行政管理人',
    TechnologyPartners: '科技服務夥伴',
    OtherProfessionalConsultants: '專業咨詢夥伴',
    detailText1: '一站式金融服務平台，通過開放、共享的夥伴合作模式提供金融服務方案',
    detailText2: '聚合金融資產、其他資產提供全球化配置方案，定制解決專業投資者需求',
    detailText3: '完整的數位化技術集成平臺,提高效率，優化流程',
    detailText4: '在金融服務和金融科技領域有豐富經驗的專家團隊',
    detailText5: '強化流程以發掘機會，同時從虛擬資產投資中產生回報並降低風險',
    detailText6: '擁有支援傳統資產管理和虛擬資產管理所需的證監會牌照及業務專長',
    ScopeOfServices: '服務內容',
    leftTitle: '專業配套服務',
    leftLi1: '公司、基金設立',
    leftLi2: '家族辦公室、EAM相關支持',
    leftLi3: '海外貸款方案',
    leftLi4: '各類專業咨詢服務',
    leftLi5: '行政管理、科技方案服務',
    rightTitle: '專屬財富管理',
    rightLi1: '權益類投資基金',
    rightLi2: '固定收益類基金',
    rightLi3: '數字貨幣基金',
    rightLi4: '專項項目投資',
    rightLi5: '第三方策略及產品',
    rightLi6: '保險產品配置 (由保險合作夥伴提供)',
    //三大核心业务支柱
    CoreTitle: '三大核心業務支柱',
    Core1: '虛擬資產管理',
    Core2: '數位化財富管理',
    Core3: '通往大中華市場的橋樑',
    OneStopService: '一站式服務',
    //一站式服务new
    FO: '家族辦公室',
    FI: '金融機構',
    EAM: '協力廠商資產管理服務',
    HF: '對沖基金',
    lefttop1: '電子遙距開戶',
    lefttop2: '資產託管',
    lefttop3: '直通式執行和結算',
    lefttop4: '交易總匯和報告',
    righttop1: '傳統經紀服務',
    righttop2: '投資顧問服務',
    righttop3: '虛擬資產基金分銷',
    // righttop4: 'Crypto Fund Distribution',
    leftbottom1: 'Access & Connectivity to:',
    leftbottom2: 'Crypto Borrowing & Lending Platforms',
    leftbottom3: 'Crypto OTC',
    leftbottom4: 'Transaction Aggregation & Reporting',
    leftbottom5: 'Derivatives & DeFi',
    rightbottom1: '虛擬資產基金與傳統資產管理:',
    rightbottom2: '資金管理和管理帳戶',
    rightbottom3: '提供協力廠商資產管理服務予高淨值資產客戶',
    bottom1: '前台至後臺部門的支持',
    bottom2: '多元化資產和產品配置',
    bottom3: '全方位互聯',
    bottom4: '健全的合規和風險管理',
    //new end
    AncillaryServices: '配套服務',
    AncillaryServicesP1: '專業諮詢',
    AncillaryServicesP2: '法務、合規',
    AncillaryServicesP3: '稅務咨詢',
    AncillaryServicesP4: 'EAM支持',
    AncillaryServicesP5: '其他專業服務',
    IndividualClients: '個人客戶',
    IndividualClientsP1: '配置方案',
    IndividualClientsP2: '規模聚合',
    IndividualClientsP3: '增值',
    IndividualClientsP4: '保值',
    IndividualClientsP5: '風險管理',
    FamilyClients: '家族客戶',
    FamilyClientsP1: '專屬財富管理',
    FamilyClientsP2: '家族辦公室規劃',
    FamilyClientsP3: '海外託管賬戶',
    FamilyClientsP4: '專屬管理人',
    FamilyClientsP5: '專屬方案',
    dialog: {
      license1: {
        title: '證監會1,4號牌說明:',
        1: '1號牌：證券交易',
        2: '1為客戶提供股票及股票期權的買賣/經紀服務',
        3: '2為客戶買賣債券',
        4: '3為客戶買入/沽出互惠基金及單位信託基金配售及包銷證券',
        5: '4號牌：就證券提供意見',
        6: '1向客戶提供有關沽出或買入證券的投資意見',
        7: '2發出有關證券的研究報告或分析',
      },
      license2: {
        title: '證監會9號牌說明:',
        1: '9號牌：提供資產管理',
        2: '1以全權委託形式為客戶管理證券或期貨合約投資組合',
        3: '2以全權委託形式管理基金',
      },
      know: '我已了解',
    },
  },
  //安昇服务
  services: {
    bannerTitle1: '合法合規丶資產安全丶',
    bannerTitle2: '操作便捷丶方案靈活',
    //合作夥伴支持模块
    PartnersSupport: '合作夥伴支持',
    PartnersSupportP1: '全面覆蓋超過10個金融服務領域',
    PartnersSupportP2S1: '效率:',
    PartnersSupportP2S2: '平均基金設立耗時只需2個月',
    PartnersSupportP2S3: '成本:',
    PartnersSupportP2S4: '比市場相關服務提供方',
    PartnersSupportP2S5: '平均節省20%',
    PartnersSupportP3: '通過平台發現更多潛在客戶',
    PartnersSupportP4: '客戶與業務雙重提升',
    Need1: '夥伴需求:',
    Need2: '機構、個人離岸設立咨詢',
    NeedPS1: '需求細節:',
    NeedPS2: '咨詢及服務內容: ',
    NeedP1S1: '管理公司/投資公司設立',
    NeedP1S2: '公司注冊、機構牌照',
    NeedP2S1: '基金設立、EAM支持',
    NeedP2S2: '海外託管賬戶開立、離岸基金注冊、行政管理、審計、律師',
    NeedP3S1: '配套服務',
    NeedP3S2: '咨詢及服務內容: 各司法區法務、合規咨詢、稅務咨詢、IT基礎設施及方案、 資金介紹、投資者關系、市場推廣',
    PartnersNeed: '產品優化',
    PartnersNeedP1S1: '完善產品頻譜',
    PartnersNeedP1S2: '互補性產品設計、產品設計優化和執行',
    Compliance: '合規要求',
    ComplianceP1S1: '基金設置',
    ComplianceP1S2: '策略+架構合作基金',
    ComplianceP2S1: '交易種類及對手',
    ComplianceP2S2: '互補性產品和交易對手',
    //定制型财富管理
    CustomisedAssetManagement: '定制型財富管理',
    CustomisedAssetManagementP1: '一站式體驗',
    CustomisedAssetManagementP2: '操作方便',
    CustomisedAssetManagementP3: '靈活的定制化方案',
    CustomisedAssetManagementP4: '優選的產品和方案',
    ClientDemand: '客戶需求: ',
    GlobalAssetAllocation: '全球資產配置',
    Need: '需求細節:',
    Solutions: '解決方案:',
    GlobalAssetAllocationP1S1: '資產保值、現金管理',
    GlobalAssetAllocationP1S2: '貨幣基金',
    GlobalAssetAllocationP2S1: '基礎資產配置 – 固定收益、信貸類投資',
    GlobalAssetAllocationP2S2: '企業債母基金、優先級定期債基金、增高收益定期債基金、增益基金',
    GlobalAssetAllocationP3S1: '基礎資產配置 – 權益類投資',
    GlobalAssetAllocationP3S2: '全球股市母基金、中概股基金',
    GlobalAssetAllocationP4S1: '定制資產配置 -另類資產基金',
    GlobalAssetAllocationP4S2: '房地產共同基金、私募債母基金、半導體等主題母基金、私募股權、風險投資',
    GlobalAssetAllocationP5S1: '特殊目的',
    GlobalAssetAllocationP5S2: '綜合性資產配置基金',
    OverseasPropertiesOwnership: '海外置業',
    OverseasPropertiesOwnershipP1S1: '實體持有',
    OverseasPropertiesOwnershipP1S2: '海外貸款方案',
    OverseasPropertiesOwnershipP2S1: '物業管理',
    OverseasPropertiesOwnershipP2S2: '專業合作夥伴服務',
    Custody: '資產託管、家族傳承',
    CustodyP1S1: '各類保險',
    CustodyP1S2: '定制化信託、保險合作夥伴方案',
    CustodyP2S1: '海外資產託管分配',
    CustodyP2S2: '定制化信託服務',
    QualityLife: '品質生活',
    QualityLifeP1S1: '醫療健康',
    QualityLifeP1S2: '醫療保險、醫療計劃',
    QualityLifeP2S1: '旅游、休閑、愛好',
    QualityLifeP2S2: '專業夥伴服務、特定項目投資',
    //专属家族方案
    DedicatedSolutionsForFamilyClients: '專屬家族方案',
    FamilyClientsP1: '一站式體驗',
    FamilyClientsP2: '操作方便',
    FamilyClientsP3: '靈活的定制化方案',
    FamilyClientsP4: '優選的產品和方案',
    FamilyClientsP5: '專屬管理人和專屬方案',
    AssetCustodyFamilyInheritance: '資產託管、家族傳承',
    AssetCustodyFamilyInheritanceP1S1: '各類保險、海外資產託管分配',
    QualityOFLife: '醫療健康、旅游、休閑、愛好',
    StructuralSupport: '架構支持',
    StructuralSupportText: '指定管理人 – 行政、審計、合規、法務、稅務 、注冊、牌照、托管',
    DedicatedStructure: '專屬架構',
    ProductSupport: '產品支持',
    ProductSupportText: '合作夥伴保險產品 – 第三方產品、合作產品、自營產品',
    OpenAndCompleteProductRange: '開放完整的產品頻譜',
  },
  privacy: {
    title: '免責聲明',
    tips: '請瀏覽條款文件到底部，點擊同意後查看網站內容',
    agree: '本人已閱讀並同意以上條款',
    1: '1. 進入並使用本網站時，閣下已同意接受以下的所有聲明。安昇控股有限公司 (安昇) 可隨時終止或更改本網頁所提供之數據、產品或服務，而毋須事先通知閣下。',
    2: '2. 本網站上的任何產品、投資或服務只能由安昇在相關司法管轄區的法律情況下提供。此處描述的產品和服務並非對所有地區的所有人都可用。只有被適用法律允許的人才能瀏覽信息和/或接受他們知道並遵守所有適用於相關限制，並有責任確信他們可以根據當地法律或司法管轄區獲取本站資訊。',
    3: '3. 本網站包含的信息僅供一般參考，並按“原樣”提供，不提供任何形式的保證，並可隨時更改，恕不另行通知。通過使用本網站，瀏覽者同意受本免責聲明內容的約束，安昇可能會不時修改並發佈在本網站上。在瀏覽本網站信息之前，您必須明確並同意遵守所有相關限制。網站上的數據不旨在提供專業、投資或任何意見或建議，因此用戶不應依賴它。用戶應在需要時尋求適當的專業或相關建議。',
    4: '4. 雖然本網址提供予閣下的數據，均來自可靠的源數據，或以此等來源為根據，但安昇不能亦不會就任何數據或數據的準確性、有效性、及時性或完整性作出任何保證，及不承擔任何責任。本網址上的數據，僅按當時情況而提供，其所包含或表達的一切資料或意見，如有任何改變，恕不會預先通知閣下。',
    5: '5. 本網址所提供的任何數據和文章，若沒有得到安昇的書面同意，均不可以透過任何媒介或方式複印、分發或出版該等數據或軟件作任何用途。如閣下由本網址下載任何資料或軟體，即表示閣下同意不會複製該等資料或軟體，或除去或隱藏該等數據所載的任何版權或其他通告或標題。',
    6: '6. 網站上的某些受限信息並非適用於所有投資者。訪問的受限信息既不針對散戶，也不提供給散戶。受限信息僅供專業投資者使用（定義見《證券及期貨條例》附表 1 第 1 部第 1 條），如果您不是專業投資者，請勿繼續訪問受限信息。',
    7: '提示：閣下提供的個人資料由安昇控股有限公司收集，我司僅會用於回復您提交的資訊查詢，不會用作往後的推廣或宣傳產品及服務用途，亦不會向第三方披露。',
    8: '（中文譯本只供參考，一切內容以英文版本為準）',
  },
  contactUs: {
    title: '聯絡我們',
    company: '安昇金融控股有限公司',
    address: '香港中環干諾道中34-37號華懋大廈19樓1902室',
    phone: '電話 ',
    email: '電郵 ',
    Email: '電郵 ',
    inquiries: '查詢資訊',
    name: '姓名',
    emailAddress: '電郵地址',
    subject: '主題',
    body: '內容',
    link: '領英 ',
    toast: '提示：閣下提供的個人資料由安昇金融控股有限公司收集，我司僅會用於回復您提交的資訊查詢，不會用作往後的推廣或宣傳產品及服務用途，亦不會向第三方披露。',
    verificationCode: '驗證碼',
    enterName: '請輸入姓名',
    enterEmailAddress: '請輸入電郵地址',
    enterSubject: '請輸入主題',
    enterBody: '請輸入內容',
    enterVerificationCode: '請輸入驗證碼',
    submit: '提交',
    cancel: '取消',
    advertise: '人才招聘',
    manager: '業務發展經理',
    editor: '文字編輯',
    duty: '崗位職責:',
    line1: '制定和實施業務戰略以實現業務目標',
    line2: '探索，開發和維護商機和業務渠道',
    line3: '準備業務項目計劃，進行演示並執行項目執行',
    line4: '在計劃以確保項目成功時認真思考',
    line5: '主要專注於中國市場',
    line6: '與中國和海外團隊協調',
    requirement: '任職要求:',
    line7: '學士學位或同等經驗',
    line8: '3-5年的業務開發經驗',
    line9: '良好的溝通和人際交往能力',
    line10: '成功的發展戰略的知識及執行',
    line11: '具有在區塊鏈，金融領域的工作經驗者優先',
    line12: '精通英語和漢語口語和書面，尤其是說流利的普通話是一個優勢',
    line13: '願意出差',
    line14: '負責撰寫相關企業品牌宣傳軟文及新聞活動稿;',
    line15: '熟悉各種網絡推廣渠道，新媒體新渠道的開發和創新，尋找高 效的新媒體平台;',
    line16: '熟悉區塊鏈、數字資產及金融方面;',
    line17: '編寫網站宣傳資料及相關產品資料;',
    line18: '收集、研究和處理網絡讀者的意見 和反饋信息;',
    line19: '其他社交平台的內容維護，如;微信、微博、臉書、Instagram、Telegram、 Medium、Twitter 等等;',
    line20: '結合公司各階段營銷推廣需求，獨立撰寫各類策劃文案;',
    line21: '及時完成上級領導指派的其他工作。',
    line22: '工作經驗2年以上;',
    line23: '中文、新聞、廣告等相關專業, 大專以上學歷;',
    line24: '對金融產品有興趣，並有一定認識和了解;',
    line25: '有良好的文字寫作功底，較強的信息採編能力，獨到的文案創作能力 ;',
    line26: '工作態度積極，有責任心，熱愛編輯、文案工作，有自我挑戰精神;',
    line27: '能編寫出突出產品特點，進行品牌情感營銷提供文案。',
    required: '必填',
    error: '一或多項有錯誤，請檢查並重試。',
    success: 'Thank you for your message. It has been sent.',
    successMsg: '提交成功',
    errorMsg: '提交失敗',
  },
  subscribe: {
    subtit: '及時獲取最新投資觀點和資訊',
    Name: '稱呼',
    LastName: '姓',
    FirstName: '名',
    Tel: '電話號碼',
    Email: '電子郵箱',
    Want: '我想訂閱',
    Channel: '了解安昇金融途徑',
    Code: '驗證碼',
    Success: '訂閱成功',
    Area: '國際區號',
    Input: '請輸入內容',
    Select: '請選擇',
    subscribe: '訂閱',
    statement: {
      1: '閣下提供的個人資料由安昇金融及其聯營公司收集，並有機會用作往後由安昇金融的不定期推廣或宣傳產品及服務用途。若要瞭解更多關於如何使用此資料，請參閱刊載於安昇金融網站的私隱資料。',
      2: '你有權隨時更改接收資訊的選擇，如你不欲再收取我們發送的資訊，可電郵至{email}。',
      3: '本人已閱讀並同意私隱資料及願意日後收取由安昇金融及其子公司發送的最新資訊。',
    },
  },
  professionalInvestor: {
    title: '僅供專業投資者參考',
    1: '請注意，頁面上的限制信息並非適用於所有投資者。您需要仔細閱讀以下信息，如果您理解並同意，請繼續操作。',
    2: '要訪問的受限信息既不針對散戶，也不提供給散戶。受限信息僅供專業投資者使用（定義見《證券及期貨條例》附表 1 第 1 部第 1 條）。本頁面上的信息僅供參考，不構成出售或購買任何金融產品或服務的要約或建議，或承諾開展或招攬業務，並且不得依賴與任何要約或銷售金融產品或服務。',
    3: '（中文譯本只供參考，一切內容以英文版本為準）',
    agree: '同意',
  },
  newsDisclaimer: {
    title: '免責聲明:',
    content:
      '投資涉及風險，包括可能損失本金。此處提供的信息僅用於說明目的，不應被視為反映任何特定的證券、策略或投資產品。它代表在特定時間對市場的一般評估，而不是對未來業績結果或市場走勢的保證。本材料不構成投資、財務、法律、稅務或其他建議；出售要約，或徵求購買基金任何證券或權益的要約；或對任何投資產品或策略的推薦。安昇不會根據本文檔中的信息徵求或推薦任何行動。此處表達的任何意見、預測或前瞻性陳述僅代表作者的觀點，截至所示日期僅用於一般信息目的。視圖可能基於未經獨立驗證的第三方數據。 安昇不同意或認可對本材料的任何再版。您全權負責根據您的投資目標、財務狀況和風險承受能力來決定任何投資產品或策略是否適合您。',
  },
}
