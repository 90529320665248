import { opt } from '../index'

const baseUrl = '/ex/user/v1/common'

export default {
  // 订阅
  subscribe: (data = {}) => opt({ url: `/ex/user/v1/base/subscribe`, data }),
  //
  captcha: (data = {}) => opt({ url: `/ex/user/v1/base/captcha`, data, method: 'get', responseBody: false, responseType: 'blob' }),
  //反馈
  feedback: (data = {}) => opt({ url: `/ex/user/v1/base/feedback`, data, method: 'post' }),
}
