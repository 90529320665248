module.exports = {
  navigation: {
    home: 'Home',
    aboutUs: 'About Us',
    platform: 'Platform',
    services: 'Services',
    insights: 'Market Observation',
    clients: 'Clients',
    contactUs: 'Contact Us',
    privacy: 'Disclaimer',
    address: 'Room 1902, 19th Floor, Chinachem Building, 34-37 Connaught Road Central, Hong Kong',
    tel: 'Tel',
    email: 'Email',
    subscribe1: 'Member Subscription',
    subscribe: 'Member Subscription',
  },
  homePage: {
    aboutUs: 'About Us',
    aboutUsInfo: 'Ascent Financial is committed to providing professional wealth management services for high-net-worth individuals in the Greater China region.',
    ourMission: 'Our Mission',
    ourMissionInfo: 'Through a highly innovative model that drives efficiency enhancement at sector level , our mission is to elevate the financial wellbeing of our clients and partners.',
    ourServices: 'Our Services',
    ourServicesInfo: 'Our business model centers around a digital platform that integrates all parties of the wealth management community in a one-stop-shopping financial marketplace.',
    more: 'More',
    middleTit: 'Customized wealth management services for high-net-worth individuals; and innovative financial solutions for business partners. All through a one-stop digital platform.',
    ourPlatform: 'Our Platform',
    ourPlatformInfo: 'Open, Shared, One-Stop, Digital',
    mandarin: 'Mandarin',
    cantonese: 'Cantonese',
    ourPartners: 'Our Partners',
    ourPartnersInfo: 'Connected, Collaborative, Unbounded, Win-win',
    carousel1: {
      tit: 'ASSET ALLOCATION FOR INDIVIDUAL CLIENTS',
      content:
        'Leveraging platform advantages, we are able to satisfy both basic asset allocation needs and customized allocation needs by simulating top-tier institutional asset allocation strategies for our investors.',
    },
    carousel2: {
      tit: 'EXCLUSIVE SERVICES FOR FAMILY CLIENTS',
      content: 'Customized design and one-stop operation for family clients. Use proper fund structure to manage the allocation, distribution and inheritance of small-group (such as family) assets.',
    },
    carousel3: {
      tit: 'COMPREHENSIVE PARTNERSHIP',
      content: 'Legality, Security,Accessibility, Flexibility',
    },
    newsTit: 'Industry Trends: Observation & Analysis',
    news1: '從比特幣看虛擬資產的資產配置價值',
    news2: '國企信用債違約、房地產投資及個人資產配置',
    news3: '去全球化影響下的家庭資產配置',
  },

  insights: {
    tit: 'Industry Trends: Observation & Analysis',
    more: 'More',
    subscribe: 'Subscribe',
    back: 'Back',
    news1: '從比特幣看虛擬資產的資產配置價值',
    news1_content: '從比特幣看虛擬資產的資產配置價值',
    news2: '國企信用債違約、房地產投資及個人資產配置',
    news2_content: '國企信用債違約、房地產投資及個人資產配置',
    news3: '去全球化影響下的家庭資產配置',
    news3_content: '去全球化影響下的家庭資產配置',
    news4: '雙重國籍的稅務風險及應對策略',
    news4_content: '雙重國籍的稅務風險及應對策略',
    news5: '第三方資產管理 (EAM/IAM)',
    news5_content: '第三方資產管理(EAM/IAM):高淨值財富管理新模式',
  },
  clients: {
    more: 'Learn more',
    title: 'Asset Management Solutions and Professional Ancillary Services',
    subhead: 'Asset allocation philosophy',
    idea: {
      a: 'Investment Goals',
      b: 'Investment Time Horizon',
      c: 'Risk Appetite',
      d: 'Tax Liability Considerations',
      e: 'Special Needs',
    },
    middleTit: 'Break Through Limitations Of Individual Investment',
    middleSubhead:
      'The advantages of service platform allow individual investors the opportunity to simulate top-tier institutional asset allocation. The needs for basic allocation and customised asset allocation are both satisfied.',
    describe: {
      more: 'More',
      basic: {
        tit: 'Basic Asset Allocation',
        content:
          'Including cash management, fixed income investment, equity investment. Investors can effortlessly achieve a basic allocation suitable for  individual needs through a basket of products.',
      },
      customised: {
        tit: 'Customised Asset Allocation',
        content:
          'In addition to what are included in Basic Asset Allocation, our customised asset allocation offers provide investors with other private alternative investment opportunities that are often inaccessible to individual investors  due to scale and exclusivity.',
      },
    },
    project: {
      tit: 'Recommended Solutions',
      subhead: 'Customized Solutions for individual and family clients with different size of wealth',
      more: 'Learn more',
      arrowStep: {
        a: 'Starting: Wealth Preservation',
        b: 'Advancing: Wealth Appreciation',
        c: 'Maturing: Inheritance',
      },
    },
    project1: {
      tit: 'Asset Size: USD$2,000,000 or below',
      step1: 'Personalised strategic funds allocation',
      step2: 'Achieving wealth preservation and appreciation through allocations.',
      advice: 'Recommended Solution: basic asset allocation',
    },
    project2: {
      tit: 'Asset Size: USD$2,000,000-$5,000,000',
      step1: 'Personalised strategic funds allocation',
      step2: 'Achieving wealth preservation and appreciation through allocations.',
      step3: 'External Asset Management',
      advice: 'Recommended Solution: basic asset allocation + small portion customisation',
    },
    project3: {
      tit: 'Asset Size: USD$5,000,000 or above',
      step1: 'Dedicated individual or family foundations',
      step2: 'Covering needs in asset allocation and family wealth inheritance at the same time',
      step3: 'External Asset Management',
      advice: 'Recommended Solution: advanced customization',
    },
    project4: {
      tit: 'Asset Size: USD$20,000,000 or above',
      step1: 'Independent individual, family foundations',
      step2: 'Covering multi-layered needs in asset allocation, family trust, SPV and private company management.',
      advice: 'Recommended Solution: premium fully-customised allocation',
    },
    framework: {
      tit: 'Rigorous structure and clear division of rights and<br />responsibilities safeguard clients’ asset security',
      fund: {
        tit: 'Fund Structure',
        content: 'Fund and external fund service providers are segregated, ensuring stringent process management and security of clients’ assets.',
      },
      authority: {
        tit: 'Authority Structure',
        content: 'Investment managers, fund administrators, auditors and custodian banks are mutually independent, with a common goal of safeguarding investors’ assets.',
      },
      eam: {
        tit: 'EAM Structure',
        content: 'Our EAM platform provides support to external asset managers in their servicing to investors.',
      },
    },
  },
  aboutUs: {
    bannerTitle: 'Better you, Better them, Better us',
    ourMission: 'Our Mission',
    ourMissionText:
      'AFH integrates financial products, services and information in an open, shared,  one-stop service platform to deliver diversified, unbounded, customized, global wealth management and asset allocation solutions. Through this highly innovative model that drives efficiency enhancement at sector level by working together with our partners, our mission is to elevate the financial wellbeing of our clients and partners.',
    ourBackground: 'Our Background',
    ourBackgroundTextP1:
      'Ascent Financial Holdings Limited (AFH) was founded to address the growing wealth management needs of high-net-worth individuals in Greater China areas. Headquartered in Hong Kong, our operation and product coverage spans key global markets with a broad partnership network. AFH, via its subsidiaries of New Vision Asset Management Limited holds Type 9 license to provide asset management and Ascent Financial Partners Limited holds Type 1 and 4 licenses to provide securities dealing and advisory. In addition, a trust and corporate services (TCSP) and Money Lending services are provided by our business partners of Wealth Protection Partners (TCSP) Company Limited and Asset Amplifier Company Limited respectively. Compliance, customer privacy and asset security are of utmost importance to AFH.',
    ourBackgroundTextP2:
      'AFH team is composed of a group of seasoned professionals in the financial service and technology sectors. Our proprietary digital service platform integrates insights of the financial market with the newest technological innovations, forging a web of close connections and collaborations with our partners and delivering personalized solutions and competitive investment products to our clients.',
    ourBackgroundTextP3:
      'Tapping into the unique advantages offered by our shared platform, with a win-win mindset as the basis, AFH provides partners with comprehensive third-party professional consulting and services including company formation, fund establishment, legal and tax advisory, IT, marketing & fund administration services etc. Our one-stop platform creates opportunities for our partners in customer acquisition, revenue generation, efficiency enhancement and new business development.',
    ourBackgroundTextP4:
      'Meantime, through working together with various institutions as partners, AFH creates diversified and flexible wealth management solutions for our customers. By breaking down barriers caused by asset scale, experience, resources, and geography, our customized solutions satisfy different needs of individual and family customers with varying wealth sizes in global asset allocation, family wealth inheritance and custody, and high-end lifestyle. We strive to meet our customers’ multifaceted demands in wealth preservation and appreciation as well as risk management.',
    ourBackgroundTextP5:
      'AFH embraces an open, sharing and altruistic corporate culture and makes sure that it is carried out in practice in our business planning and operational details. AFH takes a long view in our collaboration with partners and a holistic approach in wealth management for our customers. We aim for shared gain and a better financial future for all parties.',
    ourPlatform: 'Our Service Platform',
    ourPlatformTextP1:
      'The advantages of our integrated platform create opportunities for our partners in customer acquisition, revenue generation, and business development. The platform also brings products and allocation solutions that are otherwise unavailable to individual investors or individual advisors.',
    ourPlatformTextP2:
      'Tapping into the newest financial and technological development, the platform brings about great flexibility and efficiency to all parties involved. The interconnectivity between different parties breaks down barriers existed in traditional investment processes such as geographic boundaries, barriers between traditional and digital assets and across different asset classes, therefore unleashes tremendous unbounded opportunities for all parties.',
    ASCulture: 'Our Culture',
    ASCultureText:
      'Behind the all-embracing platform is our culture of “open, sharing and altruistic”. We take pride in making it possible for participants from any point of the wealth management value chain and all quality products to join forces at any time.  Our focus is to stay ahead of the market in truly satisfying our customers’ needs. We strive to deliver the best wealth management solutions for our customers by working together and sharing successes with our partners.',
    ourCommitment: 'Our Commitment',
    ourCommitmentText:
      'AFH places business integrity and safeguarding customer privacy and asset security at the top of our priorities. Compliance is of utmost importance to us. Ascent Financial Holdings Limited, via its subsidiaries of New Vision Asset Management Limited holds Type 9 license to provide asset management and Ascent Financial Partners Limited holds Type 1 and 4 licenses to provide securities dealing and advisory.  AFH has also cooperated with Wealth Protection Partners (TCSP) Company Limited and Asset Amplifier Company Limited to provide asset security service and money lending service respectively.',
    ourTeam: 'Our Team',
    ourTeamText: 'A team of seasoned professionals with a wealth of experience in financial services and technology sectors',
    Chairman: 'Rong Lu - Chairman',
    ChairmanDetail1: 'Over 25 years of experience in the financial industry',
    ChairmanDetail2: 'A Chairman of AFH and responsible for leading and overseeing the performance of the Board',
    ChairmanDetail3:
      'Former CEO of Huatai International. Established Huatai Securities’ overseas institutional business, including investment banking, sales & trading, research, structured private debt investment, wealth management',
    ChairmanDetail4:
      ' Founder of BOCI’s financial products business unit, member of management committee, director. Years of experience with international investment banks such as Merrill Lynch and Credit Suisse',
    ChiefExecutiveOfficer: 'Steve Yang, CFA - Chief Executive Officer',
    ChiefExecutiveOfficerDetail1: 'Over 20 years of professional experience in the field of finance and Fintech',
    ChiefExecutiveOfficerDetail2: 'A CEO of AFH and responsible for business strategic planning and development, investment strategies, risk management and marketing',
    ChiefExecutiveOfficerDetail3: 'Former partner at and Responsible Officer at HK FinTech platform Prive Technologies Ltd.',
    ChiefExecutiveOfficerDetail4: 'Former Head of Sales, Greater China at Commerzbank AG, and Head of Sales, Structured Credit Products, APAC at JP Morgan',
    Investor: 'Jay Zhao - Principal Founder / Investor',
    InvestorDetail1: 'Over 25 years of investment experience in corporate investment and financing, private equity, equity trading and portfolio management',
    InvestorDetail2: 'A Principal Founder in AFH',
    InvestorDetail3: 'Former Deputy General Manager of Silver Grant International (171.HK)',
    SeniorAdvisor: 'Anthony Ng - Senior Advisor',
    SeniorAdvisorDetail1: 'Over 20 years of finance and business operations experience with deep knowledge of equity, fixed income and foreign exchange markets',
    SeniorAdvisorDetail2: 'A Senior Advisor of AFH and responsible for providing advice on our operations and business developments',
    SeniorAdvisorDetail3: 'Co-founder of Hong Kong Virtual Asset Exchange (HKVAX)',
    SeniorAdvisorDetail4: 'Former COO at Coinsuper and Managing Director at CITIC Futures International. Previously worked at Morgan Stanley, JP Morgan and Myriad Asset Management',
    ChiefProductOfficer: 'Ken Wang - Chief Product Officer',
    ChiefProductOfficerDetail1: 'Over 25 years of investment management experience covering products trading, equity, debt and structured products, portfolio & risk management',
    ChiefProductOfficerDetail2: 'A Chief Product Officer of AFH and repsonsible for desiging new fund products and overseeing operational activities',
    ChiefProductOfficerDetail3: 'Worked at multiple international and domestic investment banks in senior positions including UBS, BOCI, Standard Chartered, Bank of Nova Scotia etc.',
    ChiefProductOfficerDetail4: 'Former Co-CIO of a hedge fund with rich experience in multi-asset investment and risk management',
    SeniorPortfolioManager: 'Kevin Loo - Head of Virtual Assets',
    SeniorPortfolioManagerDetail1: 'Over 20 years of investment management experience including 4 years of professional experience in virtual assets field',
    SeniorPortfolioManagerDetail2: 'A Senior Portfolio Manager of AFH and responsible for leading portfolio  investment, quantitative trading and research',
    SeniorPortfolioManagerDetail3: ' Specialized in digital assets, venture capital and traditional fixed income markets',
    SeniorPortfolioManagerDetail4: 'Former CIO at a digital assets hedge fund. Worked at Fidelity International, PIMCO and BNP Paribas with extensive experience in high yield emerging markets',
    ChiefClientOfficer: 'Tina Zhang - Chief Business Development Officer/ Chief Client Relationship Officer',
    ChiefClientOfficerDetail1: 'Over 10 years experience in investment and financing, investor relations, strategic planning and FinTech',
    ChiefClientOfficerDetail2:
      'A Chief Client Officer of AFH and responsible for strategic customer management with focusing on integrated client consulting across two business service lines - Customer Experience and Sales & Marketing',
    ChiefClientOfficerDetail3: 'Worked at EvergrandeGroup and Zhongrong Holding Group in the areas of project development planning, investment & financing, PR',
    ChiefClientOfficerDetail4: 'Previously worked at Huibang Investment, responsible for Australian and British projects',
    ChiefTechnologyOfficer: 'Guining Li - Chief Technology Officer',
    ChiefTechnologyOfficerDetail1: 'Over 15 years of professional experience in Technology field',
    ChiefTechnologyOfficerDetail2: 'A Chief Technology Officer of AFH and responsible for all technology systems, proccessesm software design and development within the company',
    ChiefTechnologyOfficerDetail3:
      'Previously worked at Huawei and Alipay in the areas ofsecure storage systems, distributed file systems, cloud storage systems, financial cloud development and system architecture design',
    ChiefTechnologyOfficerDetail4: 'Deep knowledge and application experience in blockchain and decentralized finance',
  },
  //安昇平台
  platform: {
    bannerTitle: 'A Fully-Licensed Robust Financial Platform',
    AscentFinancialPartners: 'Ascent Financial Partners',
    NewVisionAssetManagement: 'New Vision Asset Management',
    SFCA: '(SFC Type 1,4)',
    SFCN: '(SFC Type 9)',
    MORE: 'More',
    TCSP: 'TCSP (Trust or Company Service Provider)',
    TCSPtext: 'The services are provided by our business partners',
    LicensedMoneyLender: 'Licensed Money Lender',
    LicensedMoneyLendertext: 'Cooperated with our business partner - Asset Amplifier Company Limited',
    FundAdministrator: 'Fund Administrator',
    TechnologyPartners: 'Technology Partners',
    OtherProfessionalConsultants: 'Other Professional Consultants',
    detailText1: 'A one-stop service platform providing financial services & solutions through an open and shared partnership model.',
    detailText2: 'Portfolio integration & collective investment expand possibilities of customized global asset allocation solutions for professional investors.',
    detailText3: 'Complete digital technology integration, empowering efficiency and process optimization',
    detailText4: 'A team of seasoned professionals with a wealth of experience in financial services and technology sectors',
    detailText5: 'Robust processes to identify opportunities while generating return and mitigating risks from virtual asset investing',
    detailText6: 'Existing SFC licenses in place with operation expertise to support both traditional and virtual asset management',
    ScopeOfServices: 'Scope of Services',
    leftTitle: 'PROFESSIONAL ANCILLARY SERVICES',
    leftLi1: 'Company and Fund Establishment',
    leftLi2: 'Family Office and EAM Support',
    leftLi3: 'Overseas Loan Solutions',
    leftLi4: 'Various Professional Consultation Services',
    leftLi5: 'Administration and Technological Solutions',
    rightTitle: 'DEDICATED WEALTH MANAGEMENT',
    rightLi1: 'Equity Investment Funds',
    rightLi2: 'Fixed Income Funds',
    rightLi3: 'Fund-of-Funds',
    rightLi4: 'Special Projects Investment',
    rightLi5: 'Third-party Strategies & Products',
    rightLi6: 'Insurance Products (Provided by insurance partners)',
    //三大核心业务支柱
    CoreTitle: 'Three Core Business Pillars',
    Core1: 'Virtual Assets Management',
    Core2: 'Digitalized Wealth Management',
    Core3: 'Bridge to the Greater China Market',
    OneStopService: 'A Completed Digitalized Platform',
    //一站式服务new
    FO: 'FO',
    FI: 'FI',
    EAM: 'EAM',
    HF: 'HF',
    lefttop1: 'Digital KYC Onboarding',
    lefttop2: 'Asset Custody',
    lefttop3: 'Straight-through Execution & Settlement',
    lefttop4: 'Transaction Aggregation & Reporting',
    righttop1: 'Traditional Brokerage Services',
    righttop2: 'Investment Advisor Services',
    // righttop3: 'Direct Channel to Major Crypto Exchanges',
    righttop3: 'Crypto Fund Distribution',
    leftbottom1: 'Access & Connectivity to:',
    leftbottom2: 'Crypto Borrowing & Lending Platforms',
    leftbottom3: 'Crypto OTC',
    leftbottom4: 'Transaction Aggregation & Reporting',
    leftbottom5: 'Derivatives & DeFi',
    rightbottom1: 'VA & Traditional Asset Management:',
    rightbottom2: 'Funds Management & Managed Account',
    rightbottom3: 'EAM services for PB Clients',
    bottom1: 'Front-to-Back Office Functionalities',
    bottom2: 'Multi-Assets & Products',
    bottom3: 'All-rounded Connectivities',
    bottom4: 'Robust Compliance & Risk Management',
    //new end
    AncillaryServices: 'Ancillary Services',
    AncillaryServicesP1: 'Professional Consultation',
    AncillaryServicesP2: 'Legal and Compliance',
    AncillaryServicesP3: 'Tax advisory',
    AncillaryServicesP4: 'EAM Support',
    AncillaryServicesP5: 'Other Professional Services',
    IndividualClients: 'Individual Clients',
    IndividualClientsP1: 'Allocation Solutions',
    IndividualClientsP2: 'Portfolio Integration & Collective Investment',
    IndividualClientsP3: 'Wealth Appreciation',
    IndividualClientsP4: 'Wealth Preservation',
    IndividualClientsP5: 'Risk Management',
    FamilyClients: 'Family Clients',
    FamilyClientsP1: 'Dedicated Wealth Management',
    FamilyClientsP2: 'Family Office Planning',
    FamilyClientsP3: 'Overseas Custodian Accounts',
    FamilyClientsP4: 'Dedicated Managers',
    FamilyClientsP5: 'Dedicated Solutions',
    dialog: {
      license1: {
        title: 'Details of SFC Type 1 and 4 Licences:',
        1: 'Type 1: Dealing in Securities',
        2: '1 Providing trading/brokerage services for stocks and stock options',
        3: '2 Buying and selling bonds for clients',
        4: '3 Purchasing/selling mutual funds and unit trust fund placement and underwriting securities for clients',
        5: 'Type 4: Advising on Securities',
        6: '1 Giving investment advice to clients relating to the sale/purchase of securities',
        7: '2 Issuing research reports/analyses on securities',
      },
      license2: {
        title: 'Details of SFC Type 9 Licence:',
        1: 'Type 9: Asset Management',
        2: '1 Managing a portfolio of securities or futures contracts for clients on a discretionary basis',
        3: '2 Managing funds on a discretionary basis',
      },
      know: 'I have understood',
    },
  },
  //安昇服务
  services: {
    bannerTitle1: 'Compliance, Security,',
    bannerTitle2: 'Efficiency, Flexibility',
    //合作伙伴支持模块
    PartnersSupport: 'Partners Support',
    PartnersSupportP1: 'We cover over 10 financial service areas',
    PartnersSupportP2S1: 'Efficiency:',
    PartnersSupportP2S2: '2 months for fund establishment (on average)',
    PartnersSupportP2S3: 'Cost:',
    PartnersSupportP2S4: '20% cost saving compared to',
    PartnersSupportP2S5: 'prevailing market rate',
    PartnersSupportP3: 'Partners gain more potential clients through the platform',
    PartnersSupportP4: 'Opportunities for partners to expand client base and enhance business performance',
    Need1: 'Partners’ Need:',
    Need2: 'Consultation on Institutional / Personal Offshore Set-Up',
    NeedPS1: 'Need:',
    NeedPS2: 'Advisory & Services:',
    NeedP1S1: 'Management company / Investment company establishment',
    NeedP1S2: 'Company registration, institution licensing/Registration',
    NeedP2S1: 'Fund Establishment, EAM Support',
    NeedP2S2: 'Overseas custodian account opening, offshore fund registration, administration, audit, legal service',
    NeedP3S1: 'Ancillary services',
    NeedP3S2: 'Legal services of different jurisdictions, compliance consultation, tax advisory, IT infrastructure and solutions, capital introduction, investor relations, marketing & promotion',
    PartnersNeed: 'Product Optimization',
    PartnersNeedP1S1: 'Improve product spectrum',
    PartnersNeedP1S2: 'Strategy of complementary product, product design  optimization & implementation',
    Compliance: 'Compliance',
    ComplianceP1S1: 'Fund establishment',
    ComplianceP1S2: 'Strategising and structuring cooperation funds',
    ComplianceP2S1: 'Transaction types and counterparty',
    ComplianceP2S2: 'Complementary products and counterparties',
    //定制型财富管理
    CustomisedAssetManagement: 'Customised Asset Management',
    CustomisedAssetManagementP1: 'One-stop service experience',
    CustomisedAssetManagementP2: 'Easy to operate',
    CustomisedAssetManagementP3: 'Flexible and customised solution',
    CustomisedAssetManagementP4: 'Carefully selected financial products and solutions',
    ClientDemand: 'Client Demand:',
    GlobalAssetAllocation: 'Global Asset Allocation',
    Need: 'Need:',
    Solutions: 'Solutions:',
    GlobalAssetAllocationP1S1: 'Asset Preservation、Cash Management',
    GlobalAssetAllocationP1S2: 'Money Market Fund',
    GlobalAssetAllocationP2S1: 'Basic asset allocation – fixed income and credit investments',
    GlobalAssetAllocationP2S2: 'Corporate bond fund-of-funds, senior fixed maturity bond fund, high yield fixed maturity bond fund, high yield fund',
    GlobalAssetAllocationP3S1: 'Basic Asset Allocation – equity investments',
    GlobalAssetAllocationP3S2: 'Global equity fund-of-funds, china concept equity fund',
    GlobalAssetAllocationP4S1: 'Customised asset allocation – alternative investment fund',
    GlobalAssetAllocationP4S2: 'Real Estate mutual fund, private debt fund-of-funds, semiconductor fund-of-funds, private equity, venture capital',
    GlobalAssetAllocationP5S1: 'Special purposes',
    GlobalAssetAllocationP5S2: 'Comprehensive asset allocation Funds',
    OverseasPropertiesOwnership: 'Overseas Properties Ownership',
    OverseasPropertiesOwnershipP1S1: 'Actual property ownership',
    OverseasPropertiesOwnershipP1S2: 'Overseas loan facilities',
    OverseasPropertiesOwnershipP2S1: 'Property Management',
    OverseasPropertiesOwnershipP2S2: 'Service provision by professional partners',
    Custody: 'Custody & Family Inheritance',
    CustodyP1S1: 'Various types of insurance',
    CustodyP1S2: 'Customised trust, insurance partnership programs',
    CustodyP2S1: 'Overseas asset custody services',
    CustodyP2S2: 'Customised custodian services',
    QualityLife: 'Quality of Life',
    QualityLifeP1S1: 'Healthcare',
    QualityLifeP1S2: 'Medical insurance, healthcare plan',
    QualityLifeP2S1: 'Travel, Leisure, Hobbies',
    QualityLifeP2S2: 'Service provision by professional partners, special project investments',
    //专属家族方案
    DedicatedSolutionsForFamilyClients: 'Dedicated Solutions for Family Clients',
    FamilyClientsP1: 'One-stop service experience',
    FamilyClientsP2: 'Easy to operate',
    FamilyClientsP3: 'Flexible and customised solutions',
    FamilyClientsP4: 'Carefully selected financial products and solutions',
    FamilyClientsP5: 'Dedicated manager and scheme',
    AssetCustodyFamilyInheritance: 'Asset Custody & Family Inheritance',
    AssetCustodyFamilyInheritanceP1S1: 'Various types of insurance, overseas asset custody',
    QualityOFLife: 'Healthcare, travel, leisure, hobbies',
    StructuralSupport: 'Structural Support',
    StructuralSupportText: 'Dedicated Manager: Administration, Audit, Compliance, Legal, Tax, Registration, Licensing, Custody',
    DedicatedStructure: 'Dedicated Structure',
    ProductSupport: 'Product Support',
    ProductSupportText: 'Insurance Products by Partners – third-party products, cooperative products, self-developed products',
    OpenAndCompleteProductRange: 'Open and complete product range',
  },
  privacy: {
    title: 'Disclaimer',
    tips: 'Please scroll down to the bottom to carefully read the Terms & Conditions. Press the below button to browse the website',
    agree: 'I hereby confirm that I have read and accepted the Terms & Conditions',
    1: '1. When entering and using this website, you have agreed to accept all the following statements. Ascent Financial Holdings Limited (AFH) may terminate or change the information, products or services provided on this website at any time without prior notice to you.',
    2: '2. Any products, investments or services on this website can only be provided by AFH under legal circumstances in the relevant jurisdictions. Products and services described herein are not available to all persons in all geographic locations. Only persons who are permitted by applicable law may browse the information and/or accept that they are aware of and observe all relevant restrictions that apply to them and are responsible for satisfying themselves that they may do so under the laws or the jurisdiction from which access is obtained to this site.',
    3: '3. The information contained on this website is for general information only and is provided on an “as is” basis without warranty of any kind and may be changed at any time without prior notice.  By using this website, the viewer agrees to be bound by the content of this disclaimer as it may be amended by AFH from time to time and posted on this website. Before browsing the information on this website, you must clearly and agree to abide by all relevant restrictions. The data on the website is not intended to provide professional, investment or any advice or suggestions, so users should not rely on it. Users should seek appropriate professional or relevant advice when needed.',
    4: '4. Although the information provided to you on this website comes from reliable sources, or is based on such sources, AFH cannot and will not make any comments on the accuracy, validity, timeliness or accuracy of any data or data. Make any guarantee for completeness and assume no responsibility. The data on this website is only provided according to the current situation. All data or opinions contained or expressed in it will not be notified in advance if there are any changes.',
    5: '5. Without the written consent of AFH, any data and articles provided on this website may not be copied, distributed or published for any purpose through any medium or method. If you download any information or software from this website, you agree that you will not copy such information or software, or remove or hide any copyright or other notices or titles contained in such data.',
    6: '6. Please be informed that some restricted information on the website is not intended for all investors.The restricted information to be accessed is neither directed at, nor provided to, retail clients. The restricted information is intended for professional investors only (as defined in section 1 of Part 1 of Schedule 1 to the Securities & Futures Ordinance), please do not proceed to access the restricted information if you are not professional investor.',
    7: 'Notice: The personal information you provide is collected by Ascent Financial Holdings Limited. Our company will only use it to respond to the information inquiries you submit. It will not be used for future promotion of products and services, nor will it be disclosed to third parties.',
    8: '',
  },

  contactUs: {
    title: 'Contact Us',
    company: 'Ascent Financial Holdings Limited',
    address: 'Room 1902, 19th Floor, Chinachem Building,<br/>34-37 Connaught Road Central, Hong Kong',
    phone: 'Phone&nbsp&nbsp&nbsp&nbsp ',
    email: 'E-mail&nbsp&nbsp&nbsp&nbsp ',
    Email: 'E-mail',
    inquiries: 'For general inquiries',
    name: 'Name ',
    emailAddress: 'E-mail Address',
    subject: 'Subject ',
    body: 'Body ',
    link: 'LinkedIn ',
    toast:
      'Notice: The personal information you provide is collected by Ascent Financial Holdings Limited. Our company will only use it to respond to the information inquiries you submit. It will not be used for future promotion of products and services, nor will it be disclosed to third parties.',
    verificationCode: 'Verification code',
    enterName: 'Please enter name',
    enterEmailAddress: 'Please enter E-mail Address',
    enterSubject: 'Please enter subject',
    enterBody: 'Please enter body',
    enterVerificationCode: 'Please enter verification code',
    submit: 'SUBMIT',
    cancel: 'CANCEL',
    advertise: '人才招聘',
    manager: '业务发展经理',
    editor: '文字编辑',
    duty: '岗位职责:',
    line1: '制定和实施业务战略以实现业务目标',
    line2: '探索，开发和维护商机和业务渠道',
    line3: '准备业务项目计划，进行演示并执行项目执行',
    line4: '在计划以确保项目成功时认真思考',
    line5: '主要专注于中国市场',
    line6: '与中国和海外团队协调',
    requirement: '任职要求:',
    line7: '学士学位或同等经验',
    line8: '3-5年的业务开发经验',
    line9: '良好的沟通和人际交往能力',
    line10: '成功的发展战略的知识及执行',
    line11: '具有在区块链，金融领域的工作经验者优先',
    line12: '精通英语和汉语口语和书面，尤其是说流利的普通话是一个优势',
    line13: '愿意出差',
    line14: '负责撰写相关企业品牌宣传软文及新闻活动稿;',
    line15: '熟悉各种网络推广渠道，新媒体新渠道的开发和创新，寻找高 效的新媒体平台;',
    line16: '熟悉区块链、数字资产及金融方面',
    line17: '编写网站宣传资料及相关产品资料;',
    line18: '收集、研究和处理网络读者的意见 和反馈信息;',
    line19: '其他社交平台的内容维护，如;微信、微博、脸书、Instagram、Telegram、 Medium、Twitter 等等;',
    line20: '结合公司各阶段营销推广需求，独立撰写各类策划文案;',
    line21: '及时完成上级领导指派的其他工作。',
    line22: '工作经验2年以上;',
    line23: '中文、新闻、广告等相关专业, 大专以上学历;',
    line24: '对金融产品有兴趣，并有一定认识和了解;',
    line25: '有良好的文字写作功底, 较强的信息采编能力 , 独到的文案创作能力 ;',
    line26: '工作态度积极,有责任心,热爱编辑、文案工作,有自我挑战精神;',
    line27: '能编写出突出产品特点，进行品牌情感营销提供文案。',
    required: 'Required',
    error: 'One or more items have errors, please check and try again.',
    success: 'Thank you for your message. It has been sent.',
    successMsg: 'Submitted successfully',
    errorMsg: 'Failed to submit',
  },
  subscribe: {
    subtit: 'Timely access to our latest news and market insights',
    Name: 'Title',
    LastName: 'Surname',
    FirstName: 'Given Name',
    Tel: 'Phone Number',
    Email: 'Email Address',
    Want: 'Want Subscribe',
    Channel: 'How did you hear about us?',
    Code: 'Verification Code',
    Success: 'Success',
    Area: 'Country Code',
    Select: 'Please select',
    Input: 'Please insert content',
    subscribe: 'Subscribe',
    statement: {
      1: 'The personal data provided above is collected by Ascent Financial and its affiliates for direct marketing purposes. Information sent by Ascent Financial includes latest market updates and products. To learn more about how this information is used, please refer to our Privacy Info.',
      2: 'If you change your mind at any time about wishing to receive the information from us, you can send an email message to us at {email}。',
      3: 'I have read and agreed with the Privacy Info and agree to receive the latest updates from Ascent Financial and its affiliates.',
    },
  },
  professionalInvestor: {
    title: 'Information for Professional Investors Only',
    1: 'Please be informed that the restricted information on the page is not intended for all investors. You are required to read the following message carefully, and proceed if you understand and agree with it.',
    2: 'The restricted information to be accessed is neither directed at, nor made provided to, retail clients. The restricted information is intended for professional investors only (as defined in section 1 of Part 1 of Schedule 1 to the Securities & Futures Ordinance). The information on this page is provided for informational purposes only and does not constitute an offer or recommendation to sell or buy any financial products or services, or a promise to undertake or solicit business, and may not be relied upon in connection with any offer or sale of financial products or services.',
    3: '',
    agree: 'Agree',
  },
  newsDisclaimer: {
    title: 'Disclaimer:',
    content:
      'Investing involves risk, including possible loss of principal. The information presented herein is for illustrative purposes only and should not be considered reflective of any particular security, strategy, or investment product. It represents a general assessment of the markets at a specific time and is not a guarantee of future performance results or market movement. This material does not constitute investment, financial, legal, tax, or other advice; an offer to sell, or the solicitation of an offer to purchase any security or interest in a fund; or a recommendation for any investment product or strategy. AFH is not soliciting or recommending any action based on the information in this document. Any opinions, projections, or forward-looking statements expressed herein are solely those of the author, and are only for general informational purposes as of the date indicated. Views may be based on third-party data that has not been independently verified. AFH does not approve of or endorse any republication of this material. You are solely responsible for deciding whether any investment product or strategy is appropriate for you based upon your investment goals, financial situation and tolerance for risk.',
  },
}
