import { createRouter, createWebHistory } from "vue-router";
import index from "../views/index.vue";
const routes = [
  {
    path: "/",
    name: "index",
    redirect: "/insights",
    component: index,
    meta: { title: "行業洞見" },
    children: [
      // {
      //   path: 'home',
      //   name: 'home-page',
      //   component: () => import(/* webpackChunkName: "home-page" */ '../views/home-page'), // 主页
      // },
      // {
      //   path: 'about-us',
      //   name: 'about-us',
      //   meta: { title: '關於安昇' },
      //   component: () => import(/* webpackChunkName: "about-us" */ '../views/about-us'), // 关于安昇
      // },
      // {
      //   path: 'platform',
      //   name: 'platform',
      //   meta: { title: '安昇平台' },
      //   component: () => import(/* webpackChunkName: "platform" */ '../views/platform'), // 安昇平台
      // },
      // {
      //   path: 'services',
      //   name: 'services',
      //   meta: { title: '安昇服務' },
      //   component: () => import(/* webpackChunkName: "services" */ '../views/services'), // 安昇服务
      // },
      {
        path: "insights",
        name: "insights",
        meta: { title: "行業洞見" },
        component: () =>
          import(/* webpackChunkName: "insights" */ "../views/insights"), // 行业洞见
      },
      {
        path: "news/:id",
        name: "news",
        component: () =>
          import(
            /* webpackChunkName: "home-page" */ "../views/insights/news.vue"
          ), //
      },
      // {
      //   path: 'client',
      //   name: 'client',
      //   meta: { title: '安昇客户' },
      //   component: () => import(/* webpackChunkName: "client" */ '../views/client'), // 安昇客户
      // },
      {
        path: "contact-us",
        name: "contact-us",
        meta: { title: "聯絡我们" },
        component: () =>
          import(/* webpackChunkName: "contact-us" */ "../views/contact-us"), // 联系我们
      },
      {
        path: "privacy",
        name: "privacy",
        meta: { title: "私隱政策" },
        component: () =>
          import(/* webpackChunkName: "privacy" */ "../views/privacy"), // 隐私政策
      },
      // {
      //   path: "subscribe",
      //   name: "subscribe",
      //   meta: { title: "會員訂閱" },
      //   component: () =>
      //     import(/* webpackChunkName: "subscribe" */ "../views/user-subscribe"),
      // },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
