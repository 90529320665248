<template>
  <nav-header></nav-header>
  <router-view></router-view>
</template>

<script>
  import Header from '@/components/Header.vue'
  export default {
    components: {
      'nav-header': Header,
    },
    setup() {
      return {}
    },
  }
</script>

<style lang="scss" scoped>
</style>