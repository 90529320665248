import { createApp } from 'vue'
import App from './App.vue'
import 'default-passive-events'
import router from './router'
import installAntd from './plugins/components'
/* animate.css */
import 'animate.css'
import '@/style/global.scss'
import '@/style/antd.less'
import store from './store'
import i18n from './locales/i18n'
import mixin from '@/plugins/mixin'
// import  VConsole  from  'vconsole';
// let vConsole = new VConsole();

const app = createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .mixin(mixin)

installAntd(app)

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  store.commit('SET_CURRENT_TAB', to.name)
  document.title = to.meta.title ? `AFH-${to.meta.title}` : 'AFH'
  if (process.env.NODE_ENV === 'production' && /Android|webOS|iPhone|iPod|BlackBerry|opera mini|opera mobile/i.test(navigator.userAgent)) {
    let reg = new RegExp('(^http[s]?://[a-zA-Z0-9-.]*.com)')
    if(/\/m\//g.test(location.href)) return
    location.replace(location.href.replace(reg, '$1/m'))
  } else {
    next()
  }
})

app.mount('#app')
