module.exports = {
  navigation: {
    home: '首页',
    aboutUs: '关于安昇',
    platform: '安昇平台',
    services: '安昇服务',
    insights: '市场观察',
    clients: '安昇客户',
    contactUs: '联络我们',
    privacy: '免责声明',
    address: '香港中环干诺道中34-37号华懋大厦19楼1902室',
    tel: '电话',
    email: '电邮',
    subscribe1: '会员订阅',
    subscribe: '会员订阅',
  },
  homePage: {
    aboutUs: '了解安昇',
    aboutUsInfo: '安昇金融致力于为大中华地区高净值人士提供财富管理服务。',
    ourMission: '安昇使命',
    ourMissionInfo: '安昇致力于以带动全行业效率提升的创新模式，为安昇合作伙伴和客户谋求更健康的财富未来。',
    ourServices: '安昇服务',
    ourServicesInfo: '安昇商业模式的核心是整合财富管理社群各方于一站式金融市场的数字化平台。',
    more: '了解更多',
    middleTit: '通过一站式数字化平台，为高净值人士定制财富管理服务，<br/>为合作伙伴提供创新的金融解决方案',
    ourPlatform: '我们的平台',
    ourPlatformInfo: '开放、共享、一站式、数字化',
    mandarin: '普通话',
    cantonese: '广东话',
    ourPartners: '合作伙伴',
    ourPartnersInfo: '联结、合作、无界、共赢',
    carousel1: {
      tit: '个人客户资产配置方案',
      content: '利用平台的优势帮助个人投资者模拟高端机构资产配置，无论是基础配置还是定制配置需求都能灵活满足。',
    },
    carousel2: {
      tit: '家族客户专属财富管理',
      content: '根据需求进行设计和实施一站式操作，使用基金架构综合管理小群体(家族)资产配置、分配及传承。',
    },
    carousel3: {
      tit: '全方位的合作伙伴支持',
      content: '合法合规丶资产安全丶操作便捷丶方案灵活',
    },
    newsTit: '获取安昇最新行业趋势与分析',
    news1: '从比特币看虚拟资产的资产配置价值',
    news2: '国企信用债违约、房地产投资及个人资产配置',
    news3: '去全球化影响下的家庭资产配置',
  },
  insights: {
    tit: '获取安昇最新行业趋势观察与分析',
    more: '查看更多',
    subscribe: '订阅',
    back: '返回',
    news1: '从比特币看虚拟资产的资产配置价值',
    news1_content: '从比特币看虚拟资产的资产配置价值',
    news2: '国企信用债违约、房地产投资及个人资产配置',
    news2_content: '国企信用债违约、房地产投资及个人资产配置',
    news3: '去全球化影响下的家庭资产配置',
    news3_content: '去全球化影响下的家庭资产配置',
    news4: '双重国籍的税务风险及应对策略',
    news4_content: '双重国籍的税务风险及应对策略',
    news5: '第三方资产管理 (EAM/IAM)',
    news5_content: '第三方资产管理(EAM/IAM):<br/>高净值财富管理新模式',
  },
  clients: {
    more: '了解更多',
    title: '财富管理方案及专业配套服务',
    subhead: '资产配置理念',
    idea: {
      a: '投资目的',
      b: '投资期限',
      c: '风险容忍度',
      d: '税务责任考虑',
      e: '特殊需求',
    },
    middleTit: '突破个人配置局限',
    middleSubhead: '利用服务平台的优势帮助个人投资者模拟高端机构资产配置，<br/>无论是基础配置还是定制配置需求都能灵活满足',
    describe: {
      more: '了解更多',
      basic: {
        tit: '基础资产配置',
        content: '包括一般现金管理、固定收益投资、权益类投资。投资者可以通过一篮子产品便捷地达到合适匹配个人的基础配置。',
      },
      customised: {
        tit: '定制资产配置',
        content: '定制配置除了基础配置内容以外，还包含其他非公开的另类投资类别，此类投资产品个人受限于规模和管道，一般难以获取。',
      },
    },
    project: {
      tit: '推荐方案',
      subhead: '为不同资产规模的个人与家族客户定制不同解决方案',
      more: '了解更多',
      arrowStep: {
        a: '起步：保值',
        b: '进阶：增值',
        c: '成熟：传承价值',
      },
    },
    project1: {
      tit: '资金规模 : 200万美元以下',
      step1: '按个人需求配置策略基金',
      step2: '为个人财富配置实现保值和增值',
      advice: '配置建议: 基础资产配置',
    },
    project2: {
      tit: '资金规模 : 200-500万美元',
      step1: '按个人需求配置策略基金',
      step2: '为个人财富配置实现保值和增值',
      step3: 'EAM管理',
      advice: '配置建议: 基础+小比例定制配置',
    },
    project3: {
      tit: '资金规模 : 500万美元以上',
      step1: '专设个人、家族综合基金',
      step2: '同时完成财富配置及家族分配等需求',
      step3: 'EAM管理',
      advice: '配置建议: 定制化进阶配置',
    },
    project4: {
      tit: '资金规模 : 2000万美元以上',
      step1: '独立个人、家族基金',
      step2: '同时完成财富配置、家族分配、私人公司持有的安排和管理',
      advice: '配置建议: 全功能定制化进阶配置',
    },
    framework: {
      tit: '架构严谨，权责分明，保障客户资产安全',
      fund: {
        tit: '基金架构',
        content: '基金及独立外部服务机构权<br />责分别，确保流程严谨，<br />保护客户资产安全。',
      },
      authority: {
        tit: '权限架构',
        content: '投资经理、基金行政管理人、<br />审计师及托管银行相互独立，<br />共同保障投资者资产。',
      },
      eam: {
        tit: 'EAM架构',
        content: '提供EAM平台、助力独立资产管<br />理者为投资者提供优质服务。',
      },
    },
  },

  aboutUs: {
    bannerTitle: '投资有道，共享无界',
    ourMission: '安昇使命',
    ourMissionText:
      '安昇聚合金融产品、服务、资讯各方于开放、共用的一站式服务平台，共同为客户打造多元、无界、定制化、全球化的财富管理和配置方案。安昇致力于以带动全行业效率提升的创新模式，在变动的全球局势下，为安昇合作伙伴和客户谋求更健康的财富未来。',

    ourBackground: '安昇简介',
    ourBackgroundTextP1:
      '安昇金融控股有限公司 (安昇) 致力于为大中华地区高净值人士提供财富管理服务。安昇总部位于香港，与合作伙伴形成覆盖全球主要市场的经营和产品网路。安昇金控旗下两间子公司- 新盛资产管理有限公司持有香港证监会提供资产管理的第9 类牌照，而安昇金融有限公司持有提供证券交易和咨询的第1 类和第4 类牌照。此外，我们的业务合作伙伴 - 资保伙伴有限公司 和 Asset Amplifier Company Limited 分别提供信托及公司服务 (TCSP) 和放债业务。合法、合规、诚信以及客户的资产安全保障是安昇经营的基础。',
    ourBackgroundTextP2:
      '安昇团队由金融界和科技界资深专业人士组成。公司自主研发的数位化平台融合了对金融市场的洞见和创新科技的优势，为合作伙伴和客户创造更紧密的联结与合作条件，为客户带来个性化解决方案和优质的投资产品。',
    ourBackgroundTextP3:
      '安昇借助共用平台的优势，以共赢为合作基础，为合作伙伴提供全面的专业咨询及配套服务，包括公司和基金的设立，法务、税务的咨询，IT、市场推广、基金行政管理服务等等。安昇的一站式支持，助力合作伙伴在获客、营收、效率、新业务开发等多方面持续拓展。',
    ourBackgroundTextP4:
      '同时，安昇与多类机构展开合作，打造多元、灵活的财富管理方案。这些定制化的解决方案，突破个人理财因规模、经验、资源、地域等因素造成的制约，切实满足不同资产规模的个人或家族客户在全球资产配置、家族财富传承与托管、高端品质生活等多方面的需求，力求覆盖客户对保值、增值和风险管控的多层次要求。',
    ourBackgroundTextP5:
      '安昇信奉开放、共用、利他的企业文化，并努力落实在业务的规划和运作的细节中。安昇冀与合作伙伴长期合作，为客户进行长远而周全的财富管理与规划，谋求平台各方更好的财富未来，实现多方共赢。',
    ourPlatform: '安昇服务平台',
    ourPlatformTextP1: '平台的优势为我们的合作伙伴在客户获取、创收和业务发展方面创造了机会。该平台还带来了个人投资者或个人顾问无法获得的产品和分配解决方案。',
    ourPlatformTextP2:
      '平台对金融和科技领域最新成果的应用，又为各方带来极大的效率和灵活性，突破了传统财富管理模式中的地域限制、金融资产与数字资产之间的障碍，以及不同产品种类之间的隔阂，从而释放出惠及各方的无界无限的机会。',
    ASCulture: '安昇文化',
    ASCultureText:
      '在兼容并蓄的平台背后，是安昇开放、共享、利他的文化。我们的平台允许财富管理价值链各端点的合作伙伴和优质产品随时加入。我们的聚焦是走在市场的前面，切实满足客户的需求，我们的追求是与合作伙伴共同为客户打造理想的财富管理方案，共享服务客户的成果。',
    ourCommitment: '安昇承诺',
    ourCommitmentText:
      '安昇将诚信和保护客户私隐与财产安全置于一切要务之首，合规对我们至关重要。安昇金融金控旗下两间子公司- 新盛资产管理有限公司持有香港证监会提供资产管理的第9 类牌照，而安昇金融有限公司持有提供证券交易和咨询的第1 类和第4类牌照。此外，我们的业务合作伙伴 - 资保伙伴有限公司 和 Asset Amplifier Company Limited 分别提供信托及公司服务 (TCSP) 和放债业务。',
    ourTeam: '安昇团队',
    ourTeamText: '以金融和科技为主要背景的国际化专业团队',
    Chairman: '陆戎 - 主席',
    ChairmanDetail1: '在金融领域拥有超过25年专业经验',
    ChairmanDetail2: '现任安昇主席，负责领导和监督董事会的表现',
    ChairmanDetail3: '前华泰国际总裁，建立华泰证券海外的机构业务平台，包括投行、证券销售交易与研究及结构私募债投资，财富管理等',
    ChairmanDetail4: '中银国际金融产品板块创始人及主管、公司管理委员会委员、董事，并在美林、瑞信等国际投行任职多年',
    ChiefExecutiveOfficer: '杨祖威, CFA - 首席执行官',
    ChiefExecutiveOfficerDetail1: '在金融和金融科技领域拥有超过20年专业经验',
    ChiefExecutiveOfficerDetail2: '现任安昇首席执行官，负责业务战略规划和发展、投资战略、风险管理和行销',
    ChiefExecutiveOfficerDetail3: '前金融科技公司Prive Technologies Ltd.合伙人',
    ChiefExecutiveOfficerDetail4: '曾任德国商业银行大中华地区销售主管，摩根大通结构信用产品亚太区销售部主管',
    Investor: '赵建公 - 安昇、新盛创始人/主要投资人',
    InvestorDetail1: '在企业投融资、私募股权、股权交易和投资组合管理领域拥有超过25年的专业经验',
    InvestorDetail2: '安昇主要创始人',
    InvestorDetail3: '曾任银建国际（171.HK）副总经理',
    SeniorAdvisor: '吴炜樑 - 资深顾问',
    SeniorAdvisorDetail1: '在金融和商业运营方面拥有超过20年专业经验，在股票、固定收益、外汇方面拥有丰富的知识',
    SeniorAdvisorDetail2: '现任安昇资深顾问，负责为公司的运营和业务发展提供建议',
    SeniorAdvisorDetail3: '香港虚拟资产交易所董事',
    SeniorAdvisorDetail4: '前币成科技（香港）首席运营官、中信期货国际董事总经理，并曾任职於摩根士丹利摩根大通和万方资产管理',
    ChiefProductOfficer: '王川 - 首席产品官',
    ChiefProductOfficerDetail1: '在专业投资产品交易、投资组合及风险管理领域拥有超过25年的专业经验',
    ChiefProductOfficerDetail2: '现任安昇首席产品官，负责设计新基金产品和监督运营活动',
    ChiefProductOfficerDetail3: '曾在多家中外投行、券商公司包括瑞士银行（UBS）、中银国际、渣打，东方证券等担任资深/首席交易员',
    ChiefProductOfficerDetail4: '曾担任对冲基金经理，高级风控分析师，具备丰富的多类型投资策略及风险管理经验',
    SeniorPortfolioManager: '罗永健 - 虚拟资产业务总监/资深基金经理',
    SeniorPortfolioManagerDetail1: '在投资管理领域拥有超过20年专业经验，包括大约4年在虚拟资产领域的专业经验',
    SeniorPortfolioManagerDetail2: '现任安昇资深基金经理，负责领导投资组合投资、量化交易和研究',
    SeniorPortfolioManagerDetail3: '对另类投资市场包括: 虚拟资产、创业投资、传统固定收益以及新兴高收益市场具备丰富的知识',
    SeniorPortfolioManagerDetail4: '前数字对冲基金首席投资官、曾在富达、品浩 (PIMCO)、法国巴黎银行资产管理从事投资、风险管理及分析工作',
    ChiefClientOfficer: '张晶 - 首席业务拓展官/首席客户官',
    ChiefClientOfficerDetail1: '在投融资、投资者关系及国际合作领域拥有逾10年的专业经验，具备丰富的金融科技领域实践经验',
    ChiefClientOfficerDetail2: '现任安昇首席客户官，负责战略客户管理，专注于综合客户咨询，包括客户体验和销售与营销',
    ChiefClientOfficerDetail3: '曾任职恒大集团和中融控股集团，负责项目前期开发、投融资及公共关系',
    ChiefClientOfficerDetail4: '曾任职於汇邦投资，负责澳洲及英国开发商的项目开发、与各金融机构的战略合作谈判及投资者关系维护',
    ChiefTechnologyOfficer: '李贵宁 - 首席技术官',
    ChiefTechnologyOfficerDetail1: '在技术领域拥有超过15年的专业经验',
    ChiefTechnologyOfficerDetail2: '现任安昇首席技术官，负责公司内所有技术系统、流程软体的设计和开发',
    ChiefTechnologyOfficerDetail3: '曾任职於华为、支付宝，从事安全存储系统、分布式文件系统、云端存储系统、金融云开发和系统架构设计等方面的工作',
    ChiefTechnologyOfficerDetail4: '在区块链、去中心化金融方面有深厚的专业认识和应用经验',
  },
  //安昇平台
  platform: {
    bannerTitle: '稳健的全牌照金融平台',
    AscentFinancialPartners: '安昇金融',
    NewVisionAssetManagement: '新盛资管',
    SFCA: '(证监会1,4号牌)',
    SFCN: '(证监会9号牌)',
    MORE: '了解更多',
    TCSP: '信托或公司服务提供者 (TCSP)',
    TCSPtext: '服务由业务伙伴提供',
    LicensedMoneyLender: '持牌放债人',
    LicensedMoneyLendertext: '与业务伙伴合作 - Asset Amplifier Company Limited',
    FundAdministrator: '基金行政管理人',
    TechnologyPartners: '科技服务夥伴',
    OtherProfessionalConsultants: '专业咨询夥伴',
    detailText1: '一站式金融服务平台，通过开放、共享的伙伴合作模式提供金融​​服务方案',
    detailText2: '聚合金融资产、其他资产提供全球化配置方案，定制解决专业投资者需求',
    detailText3: '完整的数字化技术集成平台,提高效率，优化流程',
    detailText4: '在金融服务和金融科技领域有丰富经验的专家团队',
    detailText5: '强化流程以发掘机会，同时从虚拟资产投资中产生回报并降低风险',
    detailText6: '拥有支持传统资产管理和虚拟资产管理所需的证监会牌照及业务专长',
    ScopeOfServices: '服务内容',
    leftTitle: '专业配套服务',
    leftLi1: '公司、基金设立',
    leftLi2: '家族办公室、EAM相关支持',
    leftLi3: '海外贷款方案',
    leftLi4: '各类专业咨询服务',
    leftLi5: '行政管理、科技方案服务',
    rightTitle: '专属财富管理',
    rightLi1: '权益类投资基金',
    rightLi2: '固定收益类基金',
    rightLi3: '数字货币基金',
    rightLi4: '专项项目投资',
    rightLi5: '第三方策略及产品',
    rightLi6: '保险产品配置 (由保险合作伙伴提供)',
    //三大核心业务支柱
    CoreTitle: '三大核心业务支柱',
    Core1: '虚拟资产管理',
    Core2: '数字化财富管理',
    Core3: '通往大中华市场的桥梁',
    OneStopService: '一站式服务',
    //一站式服务new
    FO: '家族办公室',
    FI: '金融机构',
    EAM: '第三方资产管理服务',
    HF: '对冲基金',
    lefttop1: '电子遥距开户',
    lefttop2: '资产托管',
    lefttop3: '直通式执行和结算',
    lefttop4: '交易总汇和报告',
    righttop1: '传统经纪服务',
    righttop2: '投资顾问服务',
    righttop3: '虚拟资产基金分销',
    // righttop4: 'Crypto Fund Distribution',
    leftbottom1: 'Access & Connectivity to:',
    leftbottom2: 'Crypto Borrowing & Lending Platforms',
    leftbottom3: 'Crypto OTC',
    leftbottom4: 'Transaction Aggregation & Reporting',
    leftbottom5: 'Derivatives & DeFi',
    rightbottom1: '虚拟资产基金与传统资产管理:',
    rightbottom2: '资金管理和管理账户',
    rightbottom3: '提供第三方资产管理服务予高净值资产客户',
    bottom1: '前台至后台部门的支持',
    bottom2: '多元化资产和产品配置',
    bottom3: '全方位互联',
    bottom4: '健全的合规和风险管理',
    //new end
    AncillaryServices: '配套服务',
    AncillaryServicesP1: '专业谘询',
    AncillaryServicesP2: '法务、合规',
    AncillaryServicesP3: '税务咨询',
    AncillaryServicesP4: 'EAM支持',
    AncillaryServicesP5: '其他专业服务',
    IndividualClients: '个人客户',
    IndividualClientsP1: '配置方案',
    IndividualClientsP2: '规模聚合',
    IndividualClientsP3: '增值',
    IndividualClientsP4: '保值',
    IndividualClientsP5: '风险管理',
    FamilyClients: '家族客户',
    FamilyClientsP1: '专属财富管理',
    FamilyClientsP2: '家族办公室规划',
    FamilyClientsP3: '海外托管账户',
    FamilyClientsP4: '专属管理人',
    FamilyClientsP5: '专属方案',
    dialog: {
      license1: {
        title: '证监会1,4号牌说明:',
        1: '1号牌：证券交易',
        2: '1为客户提供股票及股票期权的买卖/经纪服务',
        3: '2为客户买卖债券',
        4: '3为客户买入/沽出互惠基金及单位信托基金配售及包销证券',
        5: '4号牌：就证券提供意见',
        6: '1向客户提供有关沽出或买入证券的投资意见',
        7: '2发出有关证券的研究报告或分析',
      },
      license2: {
        title: '证监会9号牌说明:',
        1: '9号牌：提供资产管理',
        2: '1以全权委托形式为客户管理证券或期货合约投资组合',
        3: '2以全权委托形式管理基金',
      },
      know: '我已了解',
    },
  },
  //安昇服务
  services: {
    bannerTitle1: '合法合规丶资产安全丶',
    bannerTitle2: '操作便捷丶方案灵活',
    //合作伙伴支持模块
    PartnersSupport: '合作伙伴支持',
    PartnersSupportP1: '全面覆盖超过10个金融服务领域',
    PartnersSupportP2S1: '效率:',
    PartnersSupportP2S2: '平均基金设立耗时只需2个月',
    PartnersSupportP2S3: '成本:',
    PartnersSupportP2S4: '比市场相关服务提供方',
    PartnersSupportP2S5: '平均节省20%',
    PartnersSupportP3: '通过平台发现更多潜在客户',
    PartnersSupportP4: '客户与业务双重提升',
    Need1: '伙伴需求：',
    Need2: '机构、个人离岸设立咨询',
    NeedPS1: '需求细节:',
    NeedPS2: '咨询及服务内容:',
    NeedP1S1: '管理公司/投资公司设立',
    NeedP1S2: '公司注册、机构牌照',
    NeedP2S1: '基金设立、EAM支持',
    NeedP2S2: '海外托管账户开立、离岸基金注册、行政管理、审计、律师',
    NeedP3S1: '配套服务',
    NeedP3S2: '各司法区法务、合规咨询、税务咨询、IT基础设施及方案、资金介绍、投资者关系、市场推广',
    PartnersNeed: '产品优化',
    PartnersNeedP1S1: '完善产品频谱',
    PartnersNeedP1S2: '互补性产品设计、产品设计优化和执行',
    Compliance: '合规要求',
    ComplianceP1S1: '基金设置',
    ComplianceP1S2: '策略+架构合作基金',
    ComplianceP2S1: '交易种类及对手',
    ComplianceP2S2: '互补性产品和交易对手',
    //定制型财富管理
    CustomisedAssetManagement: '定制型财富管理',
    CustomisedAssetManagementP1: '一站式体验',
    CustomisedAssetManagementP2: '操作方便',
    CustomisedAssetManagementP3: '灵活的定制化方案',
    CustomisedAssetManagementP4: '优选的产品和方案',
    ClientDemand: '客户需求:',
    GlobalAssetAllocation: '全球资产配置',
    Need: '需求细节:',
    Solutions: '解决方案:',
    GlobalAssetAllocationP1S1: '资产保值、现金管理',
    GlobalAssetAllocationP1S2: '货币基金',
    GlobalAssetAllocationP2S1: '基础资产配置 – 固定收益、信贷类投资',
    GlobalAssetAllocationP2S2: '企业债母基金、优先级定期债基金、增高收益定期债基金、增益基金',
    GlobalAssetAllocationP3S1: '基础资产配置 – 权益类投资',
    GlobalAssetAllocationP3S2: '全球股市母基金、中概股基金',
    GlobalAssetAllocationP4S1: '定制资产配置 -另类资产基金',
    GlobalAssetAllocationP4S2: '房地产共同基金、私募债母基金、半导体等主题母基金、私募股权、风险投资',
    GlobalAssetAllocationP5S1: '特殊目的',
    GlobalAssetAllocationP5S2: '综合性资产配置基金',
    OverseasPropertiesOwnership: '海外置业',
    OverseasPropertiesOwnershipP1S1: '实体持有',
    OverseasPropertiesOwnershipP1S2: '海外贷款方案',
    OverseasPropertiesOwnershipP2S1: '物业管理',
    OverseasPropertiesOwnershipP2S2: '专业合作伙伴服务',
    Custody: '资产托管、家族传承',
    CustodyP1S1: '各类保险',
    CustodyP1S2: '定制化信托、保险合作伙伴方案',
    CustodyP2S1: '海外资产托管分配',
    CustodyP2S2: '定制化信托服务',
    QualityLife: '品质生活',
    QualityLifeP1S1: '医疗健康',
    QualityLifeP1S2: '医疗保险、医疗计划',
    QualityLifeP2S1: '旅游、休闲、爱好',
    QualityLifeP2S2: '专业伙伴服务、特定项目投资',
    //专属家族方案
    DedicatedSolutionsForFamilyClients: '专属家族方案',
    FamilyClientsP1: '一站式体验',
    FamilyClientsP2: '操作方便',
    FamilyClientsP3: '灵活的定制化方案',
    FamilyClientsP4: '优选的产品和方案',
    FamilyClientsP5: '专属管理人和专属方案',
    AssetCustodyFamilyInheritance: '资产托管、家族传承',
    AssetCustodyFamilyInheritanceP1S1: '各类保险、海外资产托管分配',
    QualityOFLife: '医疗健康、旅游、休闲、爱好',
    StructuralSupport: '架构支持',
    StructuralSupportText: '指定管理人 – 行政、审计、合规、法务、税务 、注册、牌照、托管',
    DedicatedStructure: '专属架构',
    ProductSupport: '产品支持',
    ProductSupportText: '合作伙伴保险产品 – 第三方产品、合作产品、自营产品',
    OpenAndCompleteProductRange: '开放完整的产品频谱',
  },
  privacy: {
    title: '免责声明',
    tips: '请浏览条款文件到底部，点击同意后查看网站内容',
    agree: '本人已阅读并同意以上条款',
    1: '1. 进入并使用本网站时，阁下已同意接受以下的所有声明。安昇控股有限公司 (安昇) 可随时终止或更改本网页所提供之数据、产品或服务，而毋须事先通知阁下。',
    2: '2. 本网站上的任何产品、投资或服务只能由安昇在相关司法管辖区的法律情况下提供。此处描述的产品和服务并非对所有地区的所有人都可用。只有被适用法律允许的人才能浏览信息和/或接受他们知道并遵守所有适用于相关限制，并有责任确信他们可以根据当地法律或司法管辖区获取本站资讯。',
    3: '3. 本网站包含的信息仅供一般参考，并按“原样”提供，不提供任何形式的保证，并可随时更改，恕不另行通知。通过使用本网站，浏览者同意受本免责声明内容的约束，安昇可能会不时修改并发布在本网站上。在浏览本网站信息之前，您必须明确并同意遵守所有相关限制。网站上的数据不旨在提供专业、投资或任何意见或建议，因此用户不应依赖它。用户应在需要时寻求适当的专业或相关建议。',
    4: '4. 虽然本网址提供予阁下的数据，均来自可靠的源数据，或以此等来源为根据，但安昇不能亦不会就任何数据或数据的准确性、有效性、及时性或完整性作出任何保证，及不承担任何责任。本网址上的数据，仅按当时情况而提供，其所包含或表达的一切数据或意见，如有任何改变，恕不会预先通知阁下。',
    5: '5. 本网址所提供的任何数据和文章，若没有得到安昇的书面同意，均不可以透过任何媒介或方式复印、分发或出版该等数据或软件作任何用途。如阁下由本网址下载任何资料或软件，即表示阁下同意不会复制该等资料或软件，或除去或隐藏该等数据所载的任何版权或其他通告或标题。',
    6: '6. 网站上的某些受限信息并非适用于所有投资者。访问的受限信息既不针对散户，也不提供给散户。受限信息仅供专业投资者使用（定义见《证券及期货条例》附表 1 第 1 部第 1 条），如果您不是专业投资者，请勿继续访问受限信息。',
    7: '提示：阁下提供的个人资料由安昇控股有限公司收集，我司仅会用于回复您提交的资讯查询，不会用作往后的推广或宣传产品及服务用途，亦不会向第三方披露。',
    8: '（中文译本只供参考，一切内容以英文版本为准）',
  },
  contactUs: {
    title: '联络我们',
    company: '安昇金融控股有限公司',
    address: '香港中环干诺道中34-37号华懋大厦19楼1902室',
    phone: '电话 ',
    email: '电邮 ',
    Email: '电邮 ',
    inquiries: '查询资讯',
    name: '姓名',
    emailAddress: '电邮地址',
    subject: '主题',
    body: '内容',
    link: '领英 ',
    toast: '提示：阁下提供的个人资料由安昇金融控股有限公司收集，我司仅会用于回复您提交的资讯查询，不会用作往后的推广或宣传产品及服务用途，亦不会向第三方披露。',
    verificationCode: '验证码',
    enterName: '请输入姓名',
    enterEmailAddress: '请输入电邮地址',
    enterSubject: '请输入主题',
    enterBody: '请输入內容',
    enterVerificationCode: '请输入验证码',
    submit: '提交',
    cancel: '取消',
    advertise: '人才招聘',
    manager: '业务发展经理',
    editor: '文字编辑',
    duty: '岗位职责:',
    line1: '制定和实施业务战略以实现业务目标',
    line2: '探索，开发和维护商机和业务渠道',
    line3: '准备业务项目计划，进行演示并执行项目执行',
    line4: '在计划以确保项目成功时认真思考',
    line5: '主要专注于中国市场',
    line6: '与中国和海外团队协调',
    requirement: '任职要求:',
    line7: '学士学位或同等经验',
    line8: '3-5年的业务开发经验',
    line9: '良好的沟通和人际交往能力',
    line10: '成功的发展战略的知识及执行',
    line11: '具有在区块链，金融领域的工作经验者优先',
    line12: '精通英语和汉语口语和书面，尤其是说流利的普通话是一个优势',
    line13: '愿意出差',
    line14: '负责撰写相关企业品牌宣传软文及新闻活动稿;',
    line15: '熟悉各种网络推广渠道，新媒体新渠道的开发和创新，寻找高 效的新媒体平台;',
    line16: '熟悉区块链、数字资产及金融方面;',
    line17: '编写网站宣传资料及相关产品资料;',
    line18: '收集、研究和处理网络读者的意见 和反馈信息;',
    line19: '其他社交平台的内容维护，如;微信、微博、脸书、Instagram、Telegram、 Medium、Twitter 等等;',
    line20: '结合公司各阶段营销推广需求，独立撰写各类策划文案;',
    line21: '及时完成上级领导指派的其他工作。',
    line22: '工作经验2年以上;',
    line23: '中文、新闻、广告等相关专业, 大专以上学历;',
    line24: '对金融产品有兴趣，并有一定认识和了解;',
    line25: '有良好的文字写作功底，较强的信息采编能力，独到的文案创作能力;',
    line26: '工作态度积极，有责任心，热爱编辑、文案工作，有自我挑战精神;',
    line27: '能编写出突出产品特点，进行品牌情感营销提供文案。',
    required: '必填',
    error: '一或多项有错误，请检查并重试。',
    success: 'Thank you for your message. It has been sent.',
    successMsg: '提交成功',
    errorMsg: '提交失败',
  },
  subscribe: {
    subtit: '及时获取最新投资观点和资讯',
    Name: '称呼',
    LastName: '姓',
    FirstName: '名',
    Tel: '电话号码',
    Email: '电子邮箱',
    Want: '我想订阅',
    Channel: '了解安昇金融途径',
    Code: '验证码',
    Success: '订阅成功',
    Area: '国际区号',
    Input: '请输入内容',
    Select: '请选择',
    subscribe: '订阅',
    statement: {
      1: '阁下提供的个人资料由安昇金融及其联营公司收集，并有机会用作往后由安昇金融的不定期推广或宣传产品及服务用途。若要了解更多关于如何使用此资料，请参阅刊载于安昇金融网站的隐私资料。',
      2: '你有权随时更改接收资讯的选择，如你不欲再收取我们发送的资讯，可电邮至{email}。',
      3: '本人已阅读并同意隐私资料及愿意日后收取由安昇金融及其子公司发送的最新资讯。',
    },
  },
  professionalInvestor: {
    title: '仅供专业投资者参考',
    1: '请注意，页面上的限制信息并非适用于所有投资者。您需要仔细阅读以下信息，如果您理解并同意，请继续操作。',
    2: '要访问的受限信息既不针对散户，也不提供给散户。受限信息仅供专业投资者使用（定义见《证券及期货条例》附表 1 第 1 部第 1 条）。本页面上的信息仅供参考，不构成出售或购买任何金融产品或服务的要约或建议，或承诺开展或招揽业务，并且不得依赖与任何要约或销售金融产品或服务。',
    3: '(中文译本只供参考，一切内容以英文版本为准）',
    agree: '同意',
  },
  newsDisclaimer: {
    title: '免责声明:',
    content:
      '投资涉及风险，包括可能损失本金。此处提供的信息仅用于说明目的，不应被视为反映任何特定的证券、策略或投资产品。它代表在特定时间对市场的一般评估，而不是对未来业绩结果或市场走势的保证。本材料不构成投资、财务、法律、税务或其他建议；出售要约，或征求购买基金任何证券或权益的要约；或对任何投资产品或策略的推荐。安昇不会根据本文档中的信息征求或推荐任何行动。此处表达的任何意见、预测或前瞻性陈述仅代表作者的观点，截至所示日期仅用于一般信息目的。视图可能基于未经独立验证的第三方数据。安昇不同意或认可对本材料的任何再版。您全权负责根据您的投资目标、财务状况和风险承受能力来决定任何投资产品或策略是否适合您。',
  },
}
