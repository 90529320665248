<template>
  <a-layout-header
    :style="{ background: !pageYOffset ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.75)' }"
    class="header"
    id="header"
  >
    <div class="header-main">
      <img class="logo" src="../assets/img/logo.png" />
      <!-- lang and login -->
      <div class="menu-right">
        <ul class="lang-switch">
          <li
            :class="{ active: item.lang === $i18n.locale }"
            :key="item.lang"
            @click="changeLang(item.lang)"
            class="lang-btn"
            v-for="item in langs"
          >
            {{ item.name }}
          </li>
          <!-- <span
            @click="$jumpTo('subscribe')"
            class="ml-10 user-subscrible"
          >{{$t('navigation.subscribe1')}}</span>-->
          <span class="ml-20 wechat">
            <li class="iconfont icon-weixin"></li>
            <img class="qrcode" src="../assets/img/wechat-qr.jpg" />
          </span>
          <span @click="clickLinkedIn" class="ml-20 linkedin">
            <img alt src="../assets/img/linkedin.png" />
            <a
              href="https://www.linkedin.com/company/ascent-financial-holdings"
              id="link"
              target="_blank"
            ></a>
          </span>
        </ul>
      </div>
      <button @click="handleClick" class="pd-10 nav-mobile">
        <i class="iconfont icon-gengduo"></i>
      </button>
      <!-- 横版 -->
      <div class="menu-nav">
        <ul>
          <li
            :class="{ active: $store.state.currentTab === item.path }"
            :key="item.path"
            @click="jump(item.path)"
            class="nav-btn"
            v-for="item in menus"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
  </a-layout-header>
</template>

<script>
export default {
  inject: ["reload"],
  data() {
    return {
      mobileShow: false,
      pageYOffset: false,
    };
  },
  computed: {
    langs() {
      if (
        this.$store.state.siftLangs.length > 0 ||
        this.$route.name === "news"
      ) {
        return this.$store.state.langs.filter(
          (item) => this.$store.state.siftLangs.indexOf(item.lang) >= 0
        );
      } else {
        return this.$store.state.langs;
      }
    },
    menus() {
      return [
        // {
        //   name: this.$t('navigation.home'),
        //   path: 'home-page',
        // },
        // {
        //   name: this.$t('navigation.aboutUs'),
        //   path: 'about-us',
        // },
        // {
        //   name: this.$t('navigation.platform'),
        //   path: 'platform',
        // },
        // {
        //   name: this.$t('navigation.services'),
        //   path: 'services',
        // },
        // {
        //   name: this.$t('navigation.clients'),
        //   path: 'client',
        // },
        {
          name: this.$t("navigation.insights"),
          path: "insights",
        },
        {
          name: this.$t("navigation.contactUs"),
          path: "contact-us",
        },
      ];
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      //获取滚动时的高度
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 0) {
        this.pageYOffset = true;
        //滚动大于0的时候要做的操作
      } else {
        this.pageYOffset = false;
      }
      var sl = -Math.max(
        document.body.scrollLeft,
        document.documentElement.scrollLeft
      );
      document.getElementById("header").style.left = sl + "px";
    },
    handleClick() {
      this.mobileShow = !this.mobileShow;
    },
    jump(val) {
      window.scrollTo(0, 0);
      this.mobileShow = false;
      this.$router.push({ name: val });
    },
    changeLang(val) {
      this.mobileShow = false;
      this.$i18n.locale = val;
      this.$store.dispatch("FETCH_DICTIONARY");
      this.$store.commit("SET_LANGUAGE", val);
      this.reload();
    },
    clickLinkedIn() {
      document.querySelector("#link").click();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  padding: 16px 30px;
  height: 114px;
  width: 100%;
  min-width: 1200px;
  line-height: 1;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
  transition: background 0.3s;
  .header-main {
    position: relative;
    margin: 0 auto;
    max-width: 1470px;
  }
  ul {
    margin: 0;
  }
  li {
    display: inline-block;
  }
}
.logo {
  width: 214px;
}
.menu-nav-col {
  display: none;
  ul li.active {
    color: #e34134;
  }
}
.menu-nav {
  position: absolute;
  right: 100px;
  bottom: 0;
  display: inline-block;
  ul li.active {
    color: #e34134;
  }
}
.nav-btn {
  padding: 12px 15px;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  cursor: pointer;
  &:hover {
    color: #e34134;
  }
}
.menu-right {
  position: absolute;
  top: 0;
  right: 0;
}
.lang-switch .active {
  color: #e5ca78;
}
.lang-btn {
  padding: 4px 8px;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  cursor: pointer;
  &:hover {
    color: #e5ca78;
  }
}
.user-subscrible {
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  &:hover {
    color: #e5ca78;
  }
}
.wechat {
  position: relative;
  cursor: pointer;
  > li {
    color: #fff;
  }
  &:hover {
    .iconfont {
      color: #e5ca78;
    }
    .qrcode {
      display: inline-block;
    }
  }
}

.qrcode {
  display: none;
  position: absolute;
  left: -140px;
  top: 20px;
  width: 160px;
  height: 160px;
  z-index: 11;
}
.nav-mobile {
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;
  cursor: pointer;
}
.linkedin {
  position: relative;
  cursor: pointer;

  img {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-bottom: 7px;
  }
}
</style>
