import { createStore } from 'vuex'
import treeSort from '@/utils/treeSort'
import { _system } from '@/api'

export default createStore({
  state: {
    loading: false,
    currentTab: 'home-page',
    language: 'zh-HK',
    siftLangs: [],
    langs: [
      {
        name: '繁體',
        lang: 'zh-HK',
      },
      {
        name: '简体',
        lang: 'zh-CN',
      },
      { name: 'EN', lang: 'en' },
    ],
    dictionary: {},
    authorization: localStorage.getItem('authorization'),
    personal: localStorage.getItem('personal'),
    timer: null,
  },
  mutations: {
    CHANGE_LOADING(state, payload) {
      state.loading = payload
    },
    SET_CURRENT_TAB: (state, tab) => {
      state.currentTab = tab
    },
    SET_LANGUAGE: (state, lang) => {
      localStorage.setItem('language', lang)
      state.language = lang
    },
    SET_SIFTLANGS: (state, lang) => {
      state.siftLangs = [...lang]
    },
    SET_DICTNARY: (state, data) => {
      localStorage.setItem('dictionary', JSON.stringify(data))
      state.dictionary = data
    },
    SET_AUTH: (state, data) => {
      localStorage.setItem('authorization', data)
      state.authorization = data
    },
    SET_PERSONAL: (state, data) => {
      localStorage.setItem('personal', data)
      state.personal = data
    },
  },
  actions: {
    async FETCH_DICTIONARY({ commit }, params) {
      const res = [
        {
          field: "origin",
          key: "PUB",
          value: "WeChat"
        },
        {
          field: "title",
          key: "MR",
          value: "Mr."
        },
        {
          field: "pageSource",
          key: "CUSTOMER",
          value: "安昇客户"
        },
        {
          field: "topic",
          key: "MONTHLY_REPORTER",
          value: "Monthly Reporter"
        },
        {
          field: "pageItem",
          key: "MARKET_UPDATES",
          value: "Market Updates"
        },
        {
          field: "title",
          key: "MRS",
          value: "Mrs."
        },
        {
          field: "pageSource",
          key: "CONTACT",
          value: "联络我们"
        },
        {
          field: "topic",
          key: "FUND_SUBS_DETAIL",
          value: "Fund Subscription Detail"
        },
        {
          field: "pageItem",
          key: "PRESS_ROOM",
          value: "Press Room"
        },
        {
          field: "origin",
          key: "AD",
          value: "Advertisement"
        },
        {
          field: "title",
          key: "MS",
          value: "Ms."
        },
        {
          field: "pageItem",
          key: "OUR_RESEARCH",
          value: "Our Research"
        },
        {
          field: "pageSource",
          key: "NV_CONTACT",
          value: "Newvision-联络我们"
        },
        {
          field: "origin",
          key: "LINKEDIN",
          value: "LinkedIn"
        },
        {
          field: "pageItem",
          key: "INVESTMENT_LIBRARY",
          value: "Investment Library"
        },
        {
          field: "origin",
          key: "OTHER",
          value: "Others"
        }
      ]
      let data = {}
      res.forEach((item) => {
        if (Object.prototype.hasOwnProperty.call(data, item.field)) {
          data[item.field].push(item)
        } else {
          data[item.field] = []
          data[item.field].push(item)
        }
      })
      console.log(data,'data');
      commit('SET_DICTNARY', data)
      // _system.getAllDictionary().then(
      //   (res) => {
      //     let data = {}
      //     res.forEach((item) => {
      //       if (Object.prototype.hasOwnProperty.call(data, item.field)) {
      //         data[item.field].push(item)
      //       } else {
      //         data[item.field] = []
      //         data[item.field].push(item)
      //       }
      //     })
      // commit('SET_DICTNARY', data)
      //   },
      //   (res) => {
      //     commit('SET_DICTNARY', [])
      //   }
      // )
    },
  },
  modules: {},
})
