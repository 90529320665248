import { Button, Layout, Carousel, Modal, Row, Col, Pagination, Tabs, Dropdown, Menu, Image, Form, Input, Select, Checkbox, message, Table } from 'ant-design-vue'

export default (app) => {
  app.use(Layout)
  app.use(Button)
  app.use(Carousel)
  app.use(Modal)
  app.use(Tabs)
  app.use(Dropdown)
  app.use(Menu)
  app.use(Image)
  app.use(Row)
  app.use(Col)
  app.use(Pagination)
  app.use(Form)
  app.use(Input)
  app.use(Select)
  app.use(Checkbox)
  app.use(Table)

  app.config.globalProperties.$message = message
}
